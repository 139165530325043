import { Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatTabGroup, MatTabsModule } from "@angular/material/tabs";
import { TableAction, TableColumn } from "src/app/types/table.type";
import { StudentPackageLesson } from "src/app/types/studentPackageLesson.type";
import { TableComponent } from "src/app/components/table/table.component";
import { EmptyListContainerComponent } from "src/app/components/util/empty-list-container/empty-list-container.component";
import { SharedModule } from "@shared/shared.module";
import { CommonModule } from "@angular/common";
import { LessonsService } from "src/app/services/lessons/lessons.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { LessonsCommunicationService } from "../../services/lessons-communication.service";
import { Subscription } from "rxjs";
import { ButtonType } from "src/app/components/call-room/data/ButtonType";
import {
	StudentsService,
	Student,
	ActivationStatus
} from "src/app/students/students.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { LessonsStatus } from "src/app/constants/lessons-status.constants";
// import { QualityRatingService } from "src/app/lm-quality-rating/services/quality-rating.service";
// import { forkJoin } from "rxjs";
// import { map } from "rxjs/operators";

interface StudentListItem extends Student {
	isEmailSent: boolean;
	displayEmailSentMessage: boolean;
}

@Component({
	selector: "app-lessons-overview-table",
	standalone: true,
	imports: [
		MatTabsModule,
		TableComponent,
		CommonModule,
		SharedModule,
		EmptyListContainerComponent,
		TranslateModule
	],
	templateUrl: "./lessons-overview-table.component.html",
	styleUrl: "./lessons-overview-table.component.scss"
})
export class LessonsOverviewTableComponent {
	@Input() userId;
	@Input() timeZone;
	@Input() isMobile: boolean = false;
	@Input() selectedOption: number = null;
	tableColumns: TableColumn[] = [
		{
			key: "startTime",
			label: "lessons-overview.date",
			isSortable: false
		},
		{
			key: "manualAttendanceStatus",
			label: "lessons-overview.status",
			isSortable: true
		}
		// {
		// 	key: "description",
		// 	label: "lessons-overview.content",
		// 	isSortable: true
		// },
		// {
		// 	key: "studentAttendance",
		// 	label: "lessons-overview.feedback",
		// 	isSortable: true
		// }
	];
	public lessonPackageStatus: boolean;
	private statusMap = {
		completed_as_planned: this.translateService.instant(
			"lessons-overview.lesson-status.completed_as_planned"
		),
		student_cancelled_24_hours: this.translateService.instant(
			"lessons-overview.lesson-status.student_cancelled_24_hours"
		),
		student_not_appeared: this.translateService.instant(
			"lessons-overview.lesson-status.student_not_appeared"
		),
		teacher_canceled: this.translateService.instant(
			"lessons-overview.lesson-status.teacher_canceled"
		)
	};

	actions: TableAction[] = [
		{
			label: this.translateService.instant("lessons-overview.edit"),
			icon: "edit",
			callback: (row) => this.editStudent(row)
		}
	];
	@ViewChild("tabGroup") tabGroup: MatTabGroup;
	studentDataSubscription: Subscription;
	selectedStudentID: string;
	generalOVerview: StudentPackageLesson;
	lessonsList = [];
	totalLessons: number;
	page: number;
	studentData: any;
	selectedIndex: number = 0;
	sortedData: any[] = [];

	constructor(
		public route: ActivatedRoute,
		private studentsService: StudentsService,
		private lessonsService: LessonsService,
		private lessonsComunication: LessonsCommunicationService,
		private popupService: PopupService,
		private translateService: TranslateService
	) {
		this.studentDataSubscription =
			this.lessonsComunication.studentData$.subscribe((data) => {
				if (data) {
					this.studentData = data;
					this.sortedData = [];
					this.selectStudent(this.studentData);
				}
			});

		this.lessonsComunication.dataUpdated$.subscribe(() => {
			this.selectStudent(this.studentData);
		});
	}

	createRegularSession() {
		this.popupService.openCalendarEventDialog(
			this.userId,
			this.timeZone,
			true,
			this.selectedStudentID
		);
	}

	isActiveStudent(student: StudentListItem): boolean {
		return student.activationStatus === ActivationStatus.ACTIVATED;
	}

	editStudent(studentClass: any) {
		this.popupService.openLessonSummaryDialog(studentClass);
	}
	selectStudent(studentData): void {
		const { id } = studentData;
		const { status } = studentData.lessonPackage;
		this.selectedStudentID = id;
		this.resetTabIndex();
		this.lessonPackageStatus =
			LessonsStatus.ACTIVE === status || LessonsStatus.PASTDUE === status;
		this.lessonsService
			.getBookings(this.selectedStudentID, "1", "500", true)
			.subscribe(({ count, list, page }) => {
				this.lessonsList = list
					.filter((lesson) => lesson.type !== "trial")
					.map((lesson) => {
						return {
							...lesson,
							manualAttendanceStatus: this.transform(
								lesson.manualAttendanceStatus
							)
						};
					});
				this.sortedData = [...this.lessonsList];
				this.totalLessons = count;
				this.page = page;
			});
	}
	pageChange(e) {
		this.lessonsService
			.getBookings(
				this.selectedStudentID,
				(this.page + e.pageIndex).toString(),
				e.pageSize,
				true
			)
			.subscribe(({ count, list }) => {
				this.lessonsList = list;
			});
	}

	resetTabIndex(): void {
		this.selectedIndex = -1;
		setTimeout(() => {
			this.selectedIndex = 0;
		}, 0);
	}

	transform(value: string): string {
		return value ? this.statusMap[value] : "";
	}

	onSort(event: { active: string; direction: string }) {
		const { direction } = event;

		if (!direction) {
			this.sortedData = [...this.lessonsList];
			return;
		}

		const isAsc = direction === "asc";

		this.sortedData = this.lessonsList.sort((a, b) => {
			return this.compare(
				a.manualAttendanceStatus,
				b.manualAttendanceStatus,
				isAsc
			);
		});
	}

	compare(a: string, b: string, isAsc: boolean): number {
		if (!a) return isAsc ? 1 : -1;
		if (!b) return isAsc ? -1 : 1;

		return a.localeCompare(b) * (isAsc ? 1 : -1);
	}

	resendEmail(student: StudentListItem) {
		student.isEmailSent = true;
		const message = this.translateService.instant("popUp.email-sent", {
			name: student.name
		});
		this.studentsService.sendActivationEmail(student.email).subscribe(
			(res) => {
				this.displayEmailSentMessage(student);
				this.popupService.openCustomMessagePopup(
					message,
					[{ type: ButtonType.CANCEL, text: "Ok" }],
					false,
					true
				);
			},
			() => {
				student.isEmailSent = false;
			}
		);
	}

	displayEmailSentMessage(student: StudentListItem) {
		student.displayEmailSentMessage = true;
		setTimeout(() => {
			student.displayEmailSentMessage = false;
		}, 3000);
	}
}
