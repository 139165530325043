<div class="checkout-container pb-32">
	<div class="flex py-4">
		<img class="sirius-logo ml-14 max-sm:ml-4" src="/assets/icons/sirius/Sirius.png" alt="Sirius" />
		<img *ngIf="affiliate == 'th'" class="thomann-logo ml-4" src="/assets/icons/affiliate/Thomann_Unternehmen_logo.png" alt="Thomann" />
	</div>
	<div *ngIf="!externalLink || affiliate == 'th'" class="w-full bg-indigo text-white flex justify-center flex-wrap p-3">
		<!-- to do: fixed width for timer, 0 pad the numbers if single digits, add emoji, right padding -->
		@if(teacher && !userName){⏰ <app-countdown *ngIf="endDate" [endDate]="endDate"></app-countdown> Für die nächsten 24 Stunden haben
		wir den Platz bei {{ teacher }} für dich reserviert.} @else if (teacher && userName){⏰
		<app-countdown *ngIf="endDate" [endDate]="endDate"></app-countdown> Hey {{ userName }}, für die nächsten 24 Stunden haben wir den
		Platz bei {{ teacher }} für dich reserviert.} @else{<strong>⏰ Aktuell hohe Nachfrage!&nbsp;</strong>Sicher dir jetzt noch schnell
		deinen Unterrichtsplatz.}
	</div>
	<div class="steps">
		<div class="flex justify-center items-center">
			<div class="step-number pt-[2px]">✓</div>

			<div class="step-text">Lehrer gefunden <span class="emoji">🎉</span></div>
			<div class="line"></div>
			<div class="step-number pt-[2px]">✓</div>
			<div class="step-text">Probestunde absolviert <span class="emoji">💪</span></div>
			<div class="line"></div>
			<div class="step-number pt-px pr-[0.5px]">3</div>
			<div class="step-text">Angebot ausgewählt</div>
			<div class="line last"></div>
			<div class="step-number pt-px pr-[0.5px] last">4</div>
			<div class="step-text">Fertig! <span class="emoji">🏁</span></div>
		</div>
	</div>

	<h1 *ngIf="!externalLink && affiliate !== 'th'" class="mx-auto block text-center mt-8 text-3xl font-bold">
		Los geht's! <span class="text-indigo">Spare 35%</span> auf den 1. Monat mit dem Code STARTNOW35
	</h1>

	<h1 *ngIf="externalLink && affiliate !== 'th'" class="mx-auto block text-center mt-8 text-3xl md:text-4xl font-bold">
		Wähle Dein passendes <span class="text-sirius-blue">Unterrichtspaket</span>
	</h1>

	<h1 *ngIf="affiliate == 'th'" class="block text-center mt-8 text-3xl md:text-4xl mx-4 font-bold">
		Los geht's mit dem Thomann-Vorteil:
		<br />
		Du bekommst zusätzlich <span class="text-sirius-blue">2x Unterricht gratis</span>
	</h1>

	<p *ngIf="!teacher" class="text-center mb-0 md:mb-12 font-thin">
		Du legst die Kosten fest, indem du die Unterrichtshäufigkeit und deine Mitgliedschaftsdauer bestimmst.
	</p>

	<p *ngIf="(!externalLink && !affiliate && teacher) || (affiliate == 'th' && teacher)" class="text-center mb-0 md:mb-12 font-thin">
		Starte mit dem Musikunterricht und verfolge Deine musikalischen Ziele. Für 24 Stunden ist Dein Platz bei
		{{ teacher }} reserviert.
	</p>

	<div class="checkout-pricing-table flex-1 mb-16">
		<app-stripe-pricing-table [userId]="userId" [pricingTableId]="pricingTableId" [publishKey]="publishKey"></app-stripe-pricing-table>
	</div>

	<div class="recommends bg-white">
		<div class="text-text-1 text-center text-[#7a7a80] pb-4">Empfohlen von</div>
		<div class="max-w-4xl m-auto flex px-4">
			<div class="logo-box"></div>
			<div class="logo-box"></div>
			<div class="logo-box"></div>
			<div class="logo-box"></div>
			<div class="logo-box"></div>
		</div>
	</div>
	<div class="py-[32px]">
		<div class="text-center font-bold text-2xl mb-6 text-offblack">SIRIUS Bewertungen</div>
		<div class="flex w-full bg-white">
			<div class="max-w-4xl m-auto rounded-border-radius">
				<app-review-carousel></app-review-carousel>
			</div>
		</div>
	</div>
	<div class="faqs block mb-16 bg-white p-8">
		<h1 class="mx-auto block text-center mt-8 text-3xl md:text-4xl font-bold mb-16">
			Häufige Fragen & Antworten zu Musikunterricht online
		</h1>
		<div class="!max-w-4xl m-auto">
			<cdk-accordion class="faq-accordion w-full">
				@for (item of questions; track item; let index = $index) {
				<cdk-accordion-item #accordionItem="cdkAccordionItem" class="faq-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
					<div class="faq-accordion-item-header text-xl md:text-xl font-bold" (click)="accordionItem.toggle()">
						<span class="inline-flex items-center">{{ item }}</span>
						<span class="faq-accordion-item-description">
							<div class="accordion-icon" [ngClass]="accordionItem.expanded ? 'open' : 'closed'" style="width: 48px">
								<svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z" fill="currentColor"></path>
								</svg>
							</div>
						</span>
					</div>
					<div class="faq-accordion-item-body font-light" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
						{{ answers[index] }}
					</div>
				</cdk-accordion-item>
				}
			</cdk-accordion>
		</div>
		<span></span>
	</div>
</div>