@if(students && !isLoading){
<section class="lessons-overview-content">
	<app-lessons-overview-menu
		[userId]="userId"
		[timeZone]="timeZone"
		[studentsList]="students"
		[count]="count"
		[page]="page"
		[pages]="pages"
		(backButtonEvent)="previousPage()"
		(forwardButtonEvent)="nextPage()"
	/>
	@if(!isMobile){
	<app-lessons-overview-table [userId]="userId" [timeZone]="timeZone" />
	}
</section>
}@else{
<app-empty-list-container message="{{ 'students.empty-list-message' | translate }}" />
}
