import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ColorScheme } from './types/color-scheme.type';
import { GA4Service } from './services/ga4.service';
import { CustomThemeService } from './services/custom-theme.service';
import { MatDialog } from '@angular/material/dialog';
import { getSubDomain } from './helpers';
import { CookiesService } from './services/cookies/cookies.service';
import { WatchrtcService } from './services/watchrtc.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<any> = new Subject();
  public showHeader: boolean;
  public showFooter: true;
  public headerMenuStyle: ColorScheme;
  public footerStyle: ColorScheme;
  public sessionExpiring = false;
  private sessionExpirationTimeout;
  private dialogClosed: boolean;
  isInTheRoom: boolean = false;
  isLoading: boolean = true;
  isLoginPage: boolean = false;
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    // event.preventDefault();
    this.authenticationService.removeOpenedTab();
  }

  constructor(
    private cookiesService: CookiesService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    public authenticationService: AuthenticationService,
    // private ga4: GA4Service,
    public customThemeService: CustomThemeService,
    public watchRTC: WatchrtcService,
  ) {
    if (location.hostname.includes('www.')) {
      location.href = location.href.replace('www.', ''); // Workaround requested by backend
    }
    }
  ngOnInit(): void {

    this.watchRTC.init();

    /* check if cookies accepted cookie is set, if not check if
    cookieConsent cookie of wordpress exists, if yes, check if it has then consent string. */
    const cookieString = 'statistics:true';
    if (!this.cookiesService.get('cookies_accepted')){
      if (this.cookiesService.get('CookieConsent') && this.cookiesService.get('CookieConsent').includes(cookieString)) {
      this.cookiesService.set('cookies_accepted', new Date().toUTCString(), 365 * 24 * 60 * 60 * 1000);
      }
    }

    // if (!getSubDomain()) {
    // this.ga4.insertAnalyticsScript();
    // this.ga4.setUpAnalytics();
    // }

    this.authenticationService.isLoggedIn$.subscribe((loggedIn) => {
      if (!!loggedIn) {
        this.trackSessionExpiration();
      } else {
        this.clearSessionExpirationTimeout();
      }
    });
    this.isInTheRoom = this.authenticationService.isInTheRoom;
    this.authenticationService.isInTheRoomStatus$().subscribe((newValue) => {
      this.isInTheRoom = newValue;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => {
          return [
            event.state.root.firstChild.data.showHeader,
            event.state.root.firstChild.data.showFooter,
            event.state.root.firstChild.data.headerMenuStyle || 'light',
            event.state.root.firstChild.data.footerStyle || 'dark',
            event.state.root.firstChild.data.isLoginPage,
          ];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([
          showHeader,
          headerMenuStyle,
          footerStyle,
          isLoginPage,
        ]) => {
          // this.showHeader =true;
          this.showHeader = showHeader === undefined ? false : showHeader;
          // this.showFooter = showFooter === undefined ? true : showFooter;
          this.footerStyle = footerStyle;
          this.headerMenuStyle = headerMenuStyle;
          this.isLoginPage = isLoginPage === undefined ? false : isLoginPage;
        }
      );
    this.triggerLoader();
    this.authenticationService.isLoaderTriggered.subscribe((value) => {
      this.triggerLoader(value);
    });

    this.updateSeo();
  }

  private updateSeo() {
    combineLatest(
      this.router.events.pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => {
          const childComponent = event.state.root.firstChild.component;
          const childComponentName = !!childComponent
            ? (childComponent as any).prototype.constructor.name
            : '';
          return [
            event.state.root.firstChild.data.title,
            event.state.root.firstChild.data.description,
            childComponentName === 'PublicCallRoomComponent', // Video room
          ];
        })
      ),
      this.translate.onLangChange.pipe(startWith(null))
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const title = this.translate.instant(
          value[0][0] ? value[0][0] : 'platform-page.seo-title'
        );
        const description = value[0][1]
          ? this.translate.instant(value[0][1])
          : '';
        const robots = document.querySelector('meta[name="robots"]');

        document.title = title;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description);

        // Disable indexing of video rooms pages
        if (value[0][2] && !robots) {
          // Add <meta name="robots" content="noindex">
          const metaRobots = document.createElement('meta');
          metaRobots.name = 'robots';
          metaRobots.content = 'noindex';
          document.getElementsByTagName('head')[0].appendChild(metaRobots);
        } else if (robots) {
          // Remove
          robots.remove();
        }
      });
  }

  private triggerLoader(value?: number): void {
    // To hide the app while UI is being loaded
    const loadingTime: number = value || 1500;
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, loadingTime);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async loginAgain(event?: Event, logout = true): Promise<void> {
    if (event) {
      event.preventDefault();
    }
    this.sessionExpiring = false;
    this.dialog.closeAll();
    this.clearSessionExpirationTimeout();
    if (logout) {
      this.authenticationService.logOut$().subscribe(
        async() => {
          if (!this.router.routerState.snapshot.root.firstChild.params.roomid) {
            // not in call
            await this.router.navigate(['login']);
          }
        },
        async(error) => {
          this.authenticationService.resetProfile();
          this.authenticationService.clearTokens();
          await this.router.navigate(['login']);
        }
      );
    } else {
      this.authenticationService.clearTokens();
      await this.router.navigate(['login']);
    }
  }

  private trackSessionExpiration() {
    const expirationDate =
      this.authenticationService.getSessionExpirationDate();
    if (expirationDate) {
      this.checkSessionExpiration(expirationDate);
    }
  }

  private checkSessionExpiration(expiresAt: Date) {
    this.clearSessionExpirationTimeout();
    const now = new Date();
    const secondsLeft = Math.floor(
      (expiresAt.valueOf() - now.valueOf()) / 1000
    );
    if (secondsLeft <= 0) {
      console.warn(`Session expired`);
      this.loginAgain(null, false);
    } else {
      if (secondsLeft < 60 && !this.sessionExpiring && !this.dialogClosed) {
        this.sessionExpiring = true;
        this.authenticationService.updateToken().subscribe((res) => {
          this.sessionExpiring = false;
        });
      }
      const timeoutValue =
        secondsLeft < 300 ? (secondsLeft < 60 ? 500 : 1000) : 60000;
      this.sessionExpirationTimeout = window.setTimeout(() => {
        this.trackSessionExpiration(); // call this method to be sure expiredAt updated
      }, timeoutValue); // if more than 5 minutes left then check every minute, every second otherwise
    }
  }

  public dismissSessionWarning() {
    this.sessionExpiring = false;
    this.dialogClosed = true;
  }

  private clearSessionExpirationTimeout(): void {
    if (!!this.sessionExpirationTimeout) {
      window.clearTimeout(this.sessionExpirationTimeout);
      this.sessionExpirationTimeout = null;
    }
  }
}
