<button [attr.aria-label]="'close-metronome' | translate" class="mtr-btn-close" (click)="close()">
    <mat-icon>close</mat-icon>
</button>

<div class="mtr-bpm-panel">
    <div class="mtr-bpm-value-btn">
        <div class="mtr-bpm-value">
            <span id="bpmValue" aria-labelledby="bpmDescription"
                [ngClass]="['mtr-value', isMobile ? 'is-mobile' : '']">{{ bpmValue }}</span>
            <span id="bpmDescription" [ngClass]="['mtr-text', isMobile ? 'is-mobile' : '']">{{ "metronome.bpm" |
                translate }}</span>
        </div>
    </div>
    <div class="mtr-play-box">
        <button (click)="clickPlayAudio(!isAudioPlay)" [attr.aria-label]="
				(isAudioPlay
					? 'aria-label.pause-metronome'
					: 'aria-label.start-metronome'
				) | translate
			">
            <div [ngClass]="[
					'mtr-icon-media',
					isAudioPlay ? 'media-pause' : 'media-play',
					isMobile ? 'is-mobile' : ''
				]"></div>
        </button>
    </div>
</div>
<div class="mtr-bpm-scale">
    <button [attr.aria-label]="'decrease-bpm' | translate" (click)="changeBpmValue(bpmValue - bpmStep)">
        <div class="mtr-icon other bpm-value-dec"></div>
    </button>
    <mat-slider [ngClass]="[isMobile ? 'is-mobile' : '']" [min]="bpmMin" [max]="bpmMax" [step]="bpmStep"
        (change)="changeBpmValue($event.target.value)">
        <input [(ngModel)]="bpmValue" matSliderThumb />
    </mat-slider>
    <button [attr.aria-label]="'increase-bpm' | translate" (click)="changeBpmValue(bpmValue + bpmStep)">
        <div class="mtr-icon other bpm-value-inc"></div>
    </button>
</div>
<div class="mtr-portions">
    <div class="mtr-portions-list">
        <div [ngClass]="[
				'mtr-icon',
				'other',
				portion ? 'ellipse-on' : 'ellipse'
			]" *ngFor="let portion of portions"></div>
    </div>
</div>
<div class="mtr-stress">
    <div class="mtr-stress-item">
        <input type="checkbox" class="app-checkbox" id="checkboxIsStress" [(ngModel)]="isStress" />
        <label for="checkboxIsStress">{{
            "metronome.stress_first" | translate
            }}</label>
    </div>
    <div class="mtr-set-portions">
        <button [attr.aria-label]="'aria-label.decrease-beats' | translate"
            (click)="changePortion(portions.length - 1)">
            <div class="mtr-icon other portion-dec"></div>
        </button>
        <div role="text" [attr.aria-label]="'aria-label.time-signature' | translate" class="mtr-set-portions-text">
            {{ portions.length }} / 4
        </div>
        <button [attr.aria-label]="'aria-label.increase-beats' | translate"
            (click)="changePortion(portions.length + 1)">
            <div class="mtr-icon other portion-inc"></div>
        </button>
    </div>
</div>
<div class="mtr-melodies">
    <button [attr.aria-label]="'aria-label.play-quarters' | translate" (click)="clickNotes(numberOfNotes.one)">
        <div [ngClass]="[
				'mtr-icon',
				numberNotes === numberOfNotes.one
					? 'note-first-on'
					: 'note-first'
			]"></div>
    </button>
    <button [attr.aria-label]="'aria-label.play-eighths' | translate" (click)="clickNotes(numberOfNotes.two)">
        <div [ngClass]="[
				'mtr-icon',
				numberNotes === numberOfNotes.two
					? 'note-second-on'
					: 'note-second'
			]"></div>
    </button>
    <button [attr.aria-label]="'aria-label.play-sixteenths' | translate" (click)="clickNotes(numberOfNotes.four)">
        <div [ngClass]="[
				'mtr-icon',
				numberNotes === numberOfNotes.four
					? 'note-third-on'
					: 'note-third'
			]"></div>
    </button>
    <button [attr.aria-label]="'aria-label.play-triplets' | translate" (click)="clickNotes(numberOfNotes.three)">
        <div [ngClass]="[
				'mtr-icon',
				numberNotes === numberOfNotes.three
					? 'note-fourth-on'
					: 'note-fourth'
			]"></div>
    </button>
    <!-- <button (click)="clickNotes(numberOfNotes.five)">
    <div [ngClass]="['icon', (numberNotes === numberOfNotes.five ? 'note-fifth-on' :'note-fifth')]"></div>
  </button> -->
</div>