import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LibPipeModule } from "../lib-pipe/lib-pipe.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { VideoSettingsModule } from "../video-settings/video-settings.module";
import { UserActionsModule } from "../components/user-actions/user-actions.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { LoadingModule } from "../lib-visual/components/loading/loading.module";
import { MetronomeModule } from "../lib-metronome/components/metronome/metronome.module";
import { TunerModule } from "../lib-tuner/tuner/tuner.module";
import { PianoModule } from "../piano/piano.module";
import { DisplayGridParticipantsModule } from "../lib-widgets/components/display-grid-participants/display-grid-participants.module";
import { ViewMediaModule } from "../components/view-media/view-media.module";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { IconService } from "./services/icon.service";
import { UnsupportedBrowserComponent } from "../unsupported-browser/unsupported-browser.component";
import { StartComponent } from "../start/start.component";
import { SignUpComponent } from "../sign-up/sign-up.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { PasswordRequirementsComponent } from "../password-requirements/password-requirements.component";
import { RecordingsComponent } from "../recordings/recordings.component";
import { PublicCallRoomComponent } from "../components/call-room/component/public-call-room.component";
import { ChatComponent } from "../components/chat/chat.component";
import { ProfileOldComponent } from "../profile/profile-old/profile-old.component";
import { PricingComponent } from "../pricing/pricing.component";
import { PlatformComponent } from "../platform/platform.component";
import { ParticipantsListComponent } from "../participants-list/participants-list.component";
import { UserIconModule } from "../user-icon/user-icon.module";
import { MasterclassesComponent } from "../masterclasses/masterclasses.component";
import { RestoreMailSentComponent } from "../restore-mail-sent/restore-mail-sent.component";
import { MailSentComponent } from "../mail-sent/mail-sent.component";
import { LoginComponent } from "../login/login.component";
import { LobbyInfoComponent } from "../lobby-info/lobby-info.component";
import { IconTimerItemComponent } from "../icon-timer-item/icon-timer-item.component";
import { LessonsComponent } from "../lessons/lessons.component";
// import { LandingPageComponent } from '../landing-page/landing-page.component';
import { InterlocutorControlsComponent } from "../interlocutor-controls/interlocutor-controls.component";
import { CrossIconComponent } from "../cross-icon/cross-icon.component";
import { ImprintComponent } from "../imprint/imprint.component";
import { HeaderComponent } from "../header/header.component";
import { CallControlsComponent } from "../call-controls/call-controls.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { FooterComponent } from "../footer/footer.component";
import { CustomMessageComponent } from "../components/util/popup/custom-message/custom-message.component";
import { EndCallComponent } from "../end-call/end-call.component";
import { CreateProfileComponent } from "../create-profile/create-profile.component";
import { CookiesPolicyComponent } from "../cookies-policy/cookies-policy.component";
import { WarningPopupComponent } from "../components/util/popup/warning/warning-popup.component";
import { SnackBarMessageComponent } from "../components/util/popup/snack-bar-message/snack-bar-message.component";
import { RoomInviteInfoComponent } from "../components/util/popup/room-invite-info/room-invite-info.component";
import { RoomFullComponent } from "../components/util/popup/room-full/room-full.component";
import { RemovalPopupComponent } from "../components/util/popup/removal/removal-popup.component";
import { LobbyPopupBaseComponent } from "../components/util/popup/lobby/lobby-popup-base.component";
import { LobbyPopupDesktopComponent } from "../components/util/popup/lobby/desktop/lobby-popup-desktop.component";
import { LetInWaitingComponent } from "../components/util/popup/let-in-waiting/let-in-waiting.component";
import { ExpirationFreePlanComponent } from "../components/util/popup/expiration-free-plan/expiration-free-plan.component";
import { DealerRedirectComponent } from "../components/util/popup/dealer-redirect/dealer-redirect.component";
import { AccessDeniedComponent } from "../components/util/popup/access-denied/access-denied.component";
import { UserComponent } from "../components/user-call/user.component";
import { UsersListComponent } from "../components/admin/util/users/users-list/users-list.component";
import { UserEntryComponent } from "../components/admin/util/users/user/user-entry.component";
import { UserEditFormComponent } from "../components/admin/util/users/user-edit-form/user-edit-form.component";
import { UserCreateComponent } from "../components/admin/util/users/user-create/user-create.component";
import { OrganizationComponent } from "../components/admin/util/organizations/organization/organization.component";
import { OrganizationEditFormComponent } from "../components/admin/util/organizations/organization-edit-form/organization-edit-form.component";
import { OrganizationListComponent } from "../components/admin/util/organizations/organization-list/organization-list.component";
import { OrganizationCreateComponent } from "../components/admin/util/organizations/organization-create/organization-create.component";
import { AccountUpgradeFormComponent } from "../components/admin/util/accounts/account-upgrade-form/account-upgrade-form.component";
import { AccountDeleteFormComponent } from "../components/admin/util/accounts/account-delete-form/account-delete-form.component";
import { AdminLoginComponent } from "../components/admin/login/admin-login.component";
import { AdminComponent } from "../components/admin/admin.component";
import { AppComponent } from "../app.component";
import { VideoComponent } from "../video/video.component";
import { IconRoundLetterComponent } from "../icon-round-letter/icon-round-letter.component";
import { BotRoomComponent } from "../bot-room/bot-room.component";
import { CallRoomComponent } from "../components/call-room/component/call-room-component";
import { NotificationPanelComponent } from "../notification-panel/notification-panel.component";
import { DragndropDirective } from "../components/chat/dragndrop.directive";
import { RouteTransformerDirective } from "../directives/route-transformer.directive";
import { PlatformMenuComponent } from "../platform-menu/platform-menu.component";
// import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { EmojiModule } from "../emoji/emoji.module";
import { SpeedTestComponent } from "../speed-test/speed-test.component";
import { MainPanelDirective } from "../components/call-room/directives/main-panel.directive";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { RecordWarningComponent } from "../components/util/popup/record-warning/record-warning.component";
import { VolumeControlModule } from "../volume-control/volume-control.module";
import { MusicDetailsSelectorModule } from "../music-details-selector/music-details-selector.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CallHeaderComponent } from "../call-header/call-header.component";
import {
	HideForSubrolesDirective,
	ShowForSubrolesDirective
} from "../directives/subroles.directives";
import { YouTubeComponent } from "../components/util/popup/youtube/youtube.component";
import { VideoPlayerModule } from "../components/video-player/video-player.module";
import { LanguageSelectorModule } from "../language-selector/language-selector.module";
import { SelectStudentDialogComponent } from "../components/util/popup/select-student/select-student-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { NotesEditorComponent } from "../notes-editor/notes-editor.component";
import { StudentsComponent } from "../students/students.component";
import { QuillComponent } from "../quill/quill.component";
import { StudentNotesComponent } from "../student-notes/student-notes.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
// import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ConfirmLessonComponent } from "../components/util/popup/confirm-lesson/confirm-lesson.component";
import { AvailabilityComponent } from "../availability/availability.component";
import { DuplicateSelectListComponent } from "../duplicate-select-list/duplicate-select-list.component";
import { CallNotesComponent } from "../components/call-notes/call-notes.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NumberDirective } from "../directives/numbers-only.directive";
import { UpdateNoticeComponent } from "../update-notice/update-notice.component";
import { DateOverrideComponent } from "../date-override/date-override.component";
import { AddOverrideComponent } from "../add-override/add-override.component";
import { OnboardingComponent } from "../onboarding/onboarding.component";
import { FormElementsModule } from "../form-elements/form-elements.module";
import { ProfileNewComponent } from "../profile/profile-new/profile-new.component";
import { LessonStatisticsComponent } from "../lesson-statistics/lesson-statistics.component";
import { AccessControlDirective } from "../directives/access-control.directive";
import { RescheduleLessonComponent } from "../components/util/popup/reschedule-lesson/reschedule-lesson.component";
import { RescheduleCalendarComponent } from "../components/util/popup/reschedule-calendar/reschedule-calendar.component";
import { AudioMenuComponent } from "../call-controls/audio-menu/audio-menu.component";
import { VideoMenuComponent } from "../call-controls/video-menu/video-menu.component";
import { CalendarComponent } from "../calendar/calendar.component";
import { LessonsPageComponent } from "../lessons-page/lessons-page.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { StudentInfoComponent } from "../components/util/popup/student-info/student-info.component";
import { UpdateLimitComponent } from "../update-limit/update-limit.component";
import { StudentInfoPanelComponent } from "../student-info-panel/student-info-panel.component";
import { AvailabilityCalendarComponent } from "../availability-calendar/availability-calendar.component";
import { StripePricingTableComponent } from "../stripe-pricing-table/stripe-pricing-table.component";
import { CheckoutAreaComponent } from "../checkout-area/checkout-area.component";
import { TunerComponent } from "../tuner/tuner.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { VolumeMeterMiniComponent } from "../lib-visual/components/volume-meter-mini/volume-meter-mini.component";
import { ProfileTeacherMatchedComponent } from "../profile/profile-teacher-matched/profile-teacher-matched.component";
import { ProfilePageComponent } from "../profile/profile-page/profile-page.component";
import { ProfileFormComponent } from "../profile/profile-form/profile-form.component";
import { NotificationsSettingsComponent } from "../profile/notifications-settings/notifications-settings.component";
import { TalkjsChatComponent } from "../components/talkjs-chat/talkjs-chat.component";
import { MultiteacherDropdownlistComponent } from "../components/multiteacher-dropdownlist/multiteacher-dropdownlist.component";

const components = [
	UpdateLimitComponent,
	UnsupportedBrowserComponent,
	StartComponent,
	SignUpComponent,
	ResetPasswordComponent,
	PasswordRequirementsComponent,
	RecordingsComponent,
	PublicCallRoomComponent,
	ChatComponent,
	ProfileOldComponent,
	PricingComponent,
	PlatformComponent,
	ParticipantsListComponent,
	MasterclassesComponent,
	RestoreMailSentComponent,
	MailSentComponent,
	LoginComponent,
	LobbyInfoComponent,
	IconTimerItemComponent,
	LessonsComponent,
	AvailabilityComponent,
	InterlocutorControlsComponent,
	CrossIconComponent,
	ImprintComponent,
	HeaderComponent,
	CallControlsComponent,
	ForgotPasswordComponent,
	FooterComponent,
	CustomMessageComponent,
	RecordWarningComponent,
	EndCallComponent,
	DuplicateSelectListComponent,
	UpdateNoticeComponent,
	DateOverrideComponent,
	CreateProfileComponent,
	CookiesPolicyComponent,
	WarningPopupComponent,
	SnackBarMessageComponent,
	RoomInviteInfoComponent,
	RoomFullComponent,
	RemovalPopupComponent,
	LobbyPopupBaseComponent,
	LobbyPopupDesktopComponent,
	LetInWaitingComponent,
	ExpirationFreePlanComponent,
	DealerRedirectComponent,
	AccessDeniedComponent,
	UserComponent,
	UsersListComponent,
	UserEntryComponent,
	UserEditFormComponent,
	UserCreateComponent,
	OrganizationComponent,
	OrganizationEditFormComponent,
	OrganizationListComponent,
	OrganizationCreateComponent,
	AccountUpgradeFormComponent,
	AccountDeleteFormComponent,
	AdminLoginComponent,
	AdminComponent,
	AppComponent,
	VideoComponent,
	IconRoundLetterComponent,
	BotRoomComponent,
	CallRoomComponent,
	NotificationPanelComponent,
	DragndropDirective,
	RouteTransformerDirective,
	MainPanelDirective,
	PlatformMenuComponent,
	// LanguageSelectorComponent,
	SpeedTestComponent,
	CallHeaderComponent,
	ShowForSubrolesDirective,
	NumberDirective,
	HideForSubrolesDirective,
	YouTubeComponent,
	SelectStudentDialogComponent,
	NotesEditorComponent,
	StudentsComponent,
	QuillComponent,
	StudentNotesComponent,
	ConfirmLessonComponent,
	CallNotesComponent,
	AddOverrideComponent,
	OnboardingComponent,
	ProfileNewComponent,
	NotificationsSettingsComponent,
	LessonStatisticsComponent,
	RescheduleLessonComponent,
	RescheduleCalendarComponent,
	AccessControlDirective,
	LessonsPageComponent,
	ProfilePageComponent,
	ProfileFormComponent,
	ProfileTeacherMatchedComponent
];

@NgModule({
	declarations: [...components],
	imports: [
		CommonModule,
		TranslateModule,
		LibPipeModule,
		ReactiveFormsModule,
		RouterModule,
		UserIconModule,
		FormsModule,
		MatIconModule,
		MatDialogModule,
		PianoModule,
		VideoSettingsModule,
		MatSidenavModule,
		UserActionsModule,
		LoadingModule,
		MetronomeModule,
		TunerModule,
		DisplayGridParticipantsModule,
		ViewMediaModule,
		EmojiModule,
		MatButtonModule,
		MatInputModule,
		MatTableModule,
		MatPaginatorModule,
		VolumeControlModule,
		MusicDetailsSelectorModule,
		MatTooltipModule,
		VideoPlayerModule,
		LanguageSelectorModule,
		MatSelectModule,
		MatDatepickerModule,
		FormElementsModule,
		MatButtonToggleModule,
		MatNativeDateModule,
		AudioMenuComponent,
		VideoMenuComponent,
		CalendarComponent,
		MatFormFieldModule,
		StudentInfoComponent,
		StudentInfoPanelComponent,
		AvailabilityCalendarComponent,
		StripePricingTableComponent,
		CheckoutAreaComponent,
		MatProgressSpinnerModule,
		TunerComponent,
		MatCheckboxModule,
		VolumeMeterMiniComponent,
		TalkjsChatComponent,
		MultiteacherDropdownlistComponent
	],
	providers: [IconService],
	exports: [...components]
})
export class SharedModule {}
