import { Component } from "@angular/core";
import { ProfileFormComponent } from "../profile-form/profile-form.component";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { CountriesService } from "src/app/services/countries/countries.service";
import { ProfileService } from "../profile.service";
import { LanguageService } from "src/app/services/languages/language.service";

// to do: fix notifications so it doesnt call profile again

@Component({
	selector: "app-profile-teacher-matched",
	templateUrl: "./profile-teacher-matched.component.html",
	styleUrl: "./profile-teacher-matched.component.scss"
})
export class ProfileTeacherMatchedComponent extends ProfileFormComponent {
	isShowPayoutMessage: boolean;
	formSection = 0;
	tabs = [
		"account",
		"about",
		"musicalDetails",
		"profileDescription",
		"setup",
		"students",
		"payoutDetails",
		"notifications"
	];

	constructor(
		protected fb: FormBuilder,
		protected profileService: ProfileService,
		protected router: Router,
		protected authenticationService: AuthenticationService,
		protected countriesService: CountriesService,
		protected translate: TranslateService,
		protected languageService: LanguageService
	) {
		super(
			fb,
			profileService,
			router,
			authenticationService,
			countriesService,
			translate,
			languageService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.form.removeControl("terms");
		this.about.removeControl("ageConfirmation");
		this.payoutDetails.disable();
	}

	moveToStep(i) {
		this.formSection = i;
	}

	// to do, forkjoin
	saveAboutAndPhoto() {
		this.saveGroup(this.about);
		this.savePhoto();
	}
}
