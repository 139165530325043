import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NotesService } from "../services/notes/notes.service";
import { Doc } from "../quill/quill.component";
import { AuthenticationService } from "../services/authentication/authentication.service";

@Component({
	selector: "app-notes-editor",
	templateUrl: "./notes-editor.component.html",
	styleUrls: ["./notes-editor.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class NotesEditorComponent implements OnInit {
	public isSaving = false;
	@Input() studentId: string;
	@Input() teacherId: string;
	@Input() isMiniEditor = false;
	@Input() isStudent = false;
	@Input() note;
	private tasksTouched = false;
	private notesTouched = false;
	private isNewNote = true;
	isShowSaveMessage = false;

	constructor(
		private notesService: NotesService,
		private authService: AuthenticationService
	) {}

	get notesContent() {
		return this.note?.notesJson;
	}

	get tasksContent() {
		return this.note?.tasksJson;
	}

	get selfId() {
		return this.authService.profileInfo().userId;
	}

	get name() {
		return (
			this.authService.profileInfo()?.firstName +
			" " +
			this.authService.profileInfo()?.lastName
		);
	}

	ngOnInit() {
		if (this.teacherId && this.studentId) {
			this.notesService
				.getNote(this.teacherId, this.studentId)
				.subscribe((data) => {
					console.log(data);
					if (data) {
						this.isNewNote = false;
						this.note = data;
					} else {
						this.note = {
							studentId: this.studentId,
							teacherId: this.teacherId,
							title: this.name,
							tasksJson: {},
							notesJson: {},
							tasksHtml: "",
							notesHtml: ""
						};
					}
				});
			// todo remove this horrible hack to make notes work in call room
		} else if (!this.teacherId && this.studentId) {
			this.notesService.getNotes(this.studentId).subscribe(([data]) => {
				if (data) {
					this.isNewNote = false;
					this.note = data;
				} else {
					this.note = {
						studentId: this.studentId,
						teacherId: this.teacherId,
						title: this.name,
						tasksJson: {},
						notesJson: {},
						tasksHtml: "",
						notesHtml: ""
					};
				}
			});
		}
	}

	onContentChanged(doc: Doc, editor: "tasks" | "notes"): void {
		const { json, html } = doc;
		if (editor === "tasks") {
			this.note.tasksJson = json;
			this.note.tasksHtml = html;
			this.tasksTouched = true;
		} else {
			this.note.notesHtml = html;
			this.note.notesJson = json;
			this.notesTouched = true;
		}
	}

	onSave(): void {
		const data = {};
		if (this.tasksTouched) {
			data["tasksJson"] = this.note.tasksJson;
			data["tasksHtml"] = this.note.tasksHtml;
		}
		if (this.notesTouched) {
			data["notesJson"] = this.note.notesJson;
			data["notesHtml"] = this.note.notesHtml;
		}
		if (this.tasksTouched || this.notesTouched) {
			this.isSaving = true;
			this.notesService.editNote(this.note.id, data).subscribe(() => {
				this.isSaving = false;
				this.tasksTouched = false;
				this.notesTouched = false;
				this.showSaveMessage();
			});
		}
	}

	onClickSave() {
		if (this.isNewNote) {
			this.onAdd();
		} else {
			this.onSave();
		}
	}

	private showSaveMessage() {
		this.isShowSaveMessage = true;
		setTimeout(() => {
			this.isShowSaveMessage = false;
		}, 2000);
	}

	onDelete(): void {
		this.isSaving = true;
		this.notesService.deleteNote(this.note.id).subscribe(() => {
			this.isSaving = false;
		});
	}

	onAdd() {
		this.isSaving = true;
		this.notesService.addNote(this.note).subscribe(() => {
			this.isSaving = false;
			this.isNewNote = false;
			this.showSaveMessage();
		});
	}

	get isSaveDisabled(): boolean {
		return !this.tasksTouched && !this.notesTouched && !this.isSaving;
	}
}
