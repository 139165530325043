import { Component, OnInit, OnDestroy } from "@angular/core";
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { ProfileService } from "../profile.service";
import { Profile } from "../../types/profile.types";
import { confirmPasswordValidator } from "../../validators/confirm-password";
import { Timezone } from "../../models/timezone";
import { Language, LanguagesList } from "../../models/language";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../services/languages/language.service";
import { Tariff, TariffUtil } from "../../types/tariff-constants";
import {
	DISALLOW_TRAILING_SPACES,
	TEXT_NAME_MAX_LENGTH,
} from "../../constants/generic.constants";
// import { logger } from 'src/app/lib-core/logger';
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { EMAIL_VALIDATION_REG } from "../../lib-core/constants/constants";
import { Title } from "@angular/platform-browser";
import { StripeService } from "../../services/stripe/stripe.service";
import { HelpScoutService } from "../../services/help-scout-service.service";
import { GENRES, INSTRUMENTS } from "../profile.constants";

const PASS_VALIDATION_PATTERN = /(?=.*[\d\W_])/;

@Component({
	selector: "app-profile-old",
	templateUrl: "./profile-old.component.html",
	styleUrls: ["./profile-old.component.scss"],
})
export class ProfileOldComponent implements OnInit, OnDestroy {
	public form: UntypedFormGroup;
	public showFields = {
		firstName: false,
		lastName: false,
		instruments: false,
		musicGenres: false,
		timezone: false,
		email: false,
		password: false,
		language: false,
		tariff: false,
	};
	private defaultProfile = {};
	public timezones: Timezone[];
	public languages: Language[];
	public passwordUpdated = false;
	public tariffType: Tariff;
	public tariffLabel: string;
	public isTariffExpired: boolean;
	public isShowPassEmail: boolean;
	public isShowPass: boolean;
	public isShowNewPass: boolean;
	public isShowConfPass: boolean;
	// Validators feedback values
	hasEnoughPassLength: boolean;
	hasNumberOrSpecial: boolean;
	isNotEmpty: boolean;
	disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;
	instrumentsOptions = INSTRUMENTS;
	genresOptions = GENRES;

	readonly Tariff = Tariff;

	get timeZone(): string {
		if (Array.isArray(this.form.get("timezone").value)) {
			return this.form.get("timezone").value[0];
		}
		return this.form.get("timezone").value;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private profileService: ProfileService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private title: Title,
		private stripeService: StripeService,
		private helpScoutService: HelpScoutService
	) {}

	ngOnInit(): void {
		this.helpScoutService.showBeacon();
		this.initForm();
		this.loadLanguages()
			.then(() => {
				return this.loadTimezones();
			})
			.then(() => {
				this.loadProfile();
			})
			.catch((error) => {
				console.error("error loading data", error);
			});
		this.title.setTitle(
			this.translateService.instant("profile-page.seo-title")
		);
	}

	ngOnDestroy() {
		this.helpScoutService.destroyBeacon();
	}

	private initForm(): void {
		this.form = this.fb.group({
			firstName: [
				"",
				[
					Validators.required,
					Validators.maxLength(TEXT_NAME_MAX_LENGTH),
				],
			],
			lastName: [
				"",
				[
					Validators.required,
					Validators.maxLength(TEXT_NAME_MAX_LENGTH),
				],
			],
			instruments: [[""], Validators.required],
			musicGenres: [[""], Validators.required],
			timezone: ["", [Validators.required]],
			emailGroup: this.fb.group({
				email: [
					"",
					[
						Validators.required,
						Validators.pattern(
							EMAIL_VALIDATION_REG
						) /*Validators.email*/,
						Validators.maxLength(TEXT_NAME_MAX_LENGTH),
					],
				],
				password: ["", [Validators.required]],
			}),
			passwordGroup: this.fb.group(
				{
					password: ["", [Validators.required]],
					newPassword: [
						"",
						[
							Validators.required,
							Validators.minLength(8),
							Validators.pattern(PASS_VALIDATION_PATTERN),
						],
					],
					confirmPassword: [
						"",
						[
							Validators.required,
							Validators.pattern(PASS_VALIDATION_PATTERN),
						],
					],
				},
				{ validators: confirmPasswordValidator }
			),
			language: ["", [Validators.required]],
		});
		this.form
			.get("passwordGroup.newPassword")
			.valueChanges.subscribe(() => {
				this.updatePasswordValidity();
			});
	}

	private loadProfile(): void {
		this.profileService.get().subscribe((profile: Profile) => {
			this.updateDefaultProfile(profile);
			this.updateForm(profile);
			this.tariffType = profile.subscription.type;
			this.tariffLabel = TariffUtil.getLabel(this.tariffType);
			this.isTariffExpired = profile.subscription.expired;
			const selectedLanguage = this.languageService.languages.find(
				(lang) => lang._id === profile.languageId
			);
			localStorage.setItem("language", selectedLanguage.iso);
			if (selectedLanguage) {
				this.languageService.selectedLangId = selectedLanguage._id;
				this.languageService.selectedLocale = selectedLanguage.iso;
			}
		});
	}

	public showLessonManagement() {
		localStorage.setItem("show-lesson-management", "true");
		this.closeField("lesson-management");
	}
	public hideLessonManagement() {
		localStorage.setItem("show-lesson-management", "false");
		this.closeField("lesson-management");
	}

	private loadTimezones(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				this.profileService.getTimezones().subscribe(
					(timezones: Timezone[]) => {
						this.timezones = timezones;
						resolve(null);
					},
					(error) => {
						reject();
					}
				);
			}
		);
	}

	private loadLanguages(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				if (
					!this.languageService.languages ||
					!this.languageService.languages.length ||
					this.languageService.languages.length === 0
				) {
					this.profileService.getLanguages().subscribe(
						(languages: LanguagesList) => {
							this.languageService.languages = this.languages =
								languages.list;
							resolve(null);
						},
						(error) => {
							reject();
						}
					);
				} else {
					this.languages = this.languageService.languages;
					resolve(null);
				}
			}
		);
	}

	private updateForm(profile: Profile): void {
		this.form.patchValue({
			firstName: profile.firstName,
			lastName: profile.lastName,
			instruments: profile.instruments,
			musicGenres: profile.musicGenres,
			timezone: profile.timezone,
			emailGroup: {
				email: profile.email,
			},
			language: profile.languageId,
		});
	}

	updateDefaultProfile(profile): void {
		const fieldsMap = {
			firstName: "firstName",
			lastName: "lastName",
			instruments: "instruments",
			musicGenres: "musicGenres",
			timezone: "timezone",
			email: "email",
			language: "language",
		};

		for (const i of Object.keys(fieldsMap)) {
			this.defaultProfile[fieldsMap[i]] = profile[i];
		}
	}

	public toggleShowPassEmail() {
		this.isShowPassEmail = !this.isShowPassEmail;
	}

	public toggleShowPass() {
		this.isShowPass = !this.isShowPass;
	}

	public toggleShowNewPass() {
		this.isShowNewPass = !this.isShowNewPass;
	}

	public toggleShowConfPass() {
		this.isShowConfPass = !this.isShowConfPass;
	}

	public showField(fieldName: string): void {
		this.showFields[fieldName] = true;

		if (fieldName === "password") {
			this.passwordUpdated = false;
		}
	}

	public saveField(fieldName: string, data: any, fieldGroup?: string): void {
		this.profileService.save(data).subscribe(
			(profile: Profile) => {
				this.defaultProfile[fieldName] = this.form.get(
					fieldGroup ? `${fieldGroup}.${fieldName}` : fieldName
				).value;

				this.showFields[fieldName] = false;

				if (fieldName === "password") {
					this.passwordUpdated = true;
				} else if (fieldName === "language") {
					const selectedLanguage = this.languages.find(
						(language: Language) => language._id === data.language
					);
					if (selectedLanguage) {
						const selectedLanguageIso = selectedLanguage.iso;
						localStorage.setItem("language", selectedLanguageIso);
						this.translateService.use(selectedLanguageIso);
					}
				}
				this.authenticationService.resetProfile();
				this.authenticationService.profile.finally();
			},
			(err) => {
				if (["password"].includes(fieldName)) {
					this.form
						.get(fieldName + "Group." + fieldName)
						.setErrors({ is: true });
				} else if ("email" === fieldName) {
					const description = !!err.error
						? err.error.description
						: null;
					const status = !!err.error ? err.error.status : null;
					const message = !!err.error ? err.error.message : null;
					let errorField = "";
					errorField =
						!errorField && description === "Email has wrong format!"
							? "email"
							: errorField;
					errorField =
						(!errorField && description === "Wrong password!") ||
						description === "Cannot update user profile"
							? "password"
							: errorField;
					if (!!errorField) {
						this.form
							.get("emailGroup." + errorField)
							.setErrors({ is: true });
					} else {
						errorField =
							description.includes("Data consistency problem") &&
							status === 409 &&
							message === "Conflict"
								? "email"
								: errorField;
						if (!!errorField) {
							this.form
								.get("emailGroup." + errorField)
								.setErrors({ emailExists: true });
						}
					}
				}
			}
		);
	}

	public cancelField(fieldName: string, fieldGroup?: string): void {
		if (fieldGroup) {
			this.form.get(fieldGroup).reset();
		}

		if (fieldName !== "password") {
			this.form
				.get(fieldGroup ? `${fieldGroup}.${fieldName}` : fieldName)
				.setValue(this.defaultProfile[fieldName]);
		}

		this.showFields[fieldName] = false;
	}

	public updatePasswordValidity(): void {
		const passInput = this.form.get("passwordGroup.newPassword");
		const hasErrors = passInput.errors;
		this.isNotEmpty =
			!hasErrors || (hasErrors && !passInput.errors.required);
		this.hasEnoughPassLength =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.minlength);
		this.hasNumberOrSpecial =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.pattern);
	}

	async openPortal() {
		const { userId } = await this.authenticationService.profile;
		this.stripeService.openPortalSession(userId);
	}

	public closeField(fieldName: string) {
		this.showFields[fieldName] = false;
	}
}
