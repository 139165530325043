import {
	Component,
	NgZone,
	EventEmitter,
	Output,
	ViewEncapsulation
} from "@angular/core";
import { MediaProperiesUtil } from "src/app/lib-setting/components/setting-popup/setting-popup.interface";
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormGroup
} from "@angular/forms";
import { from, Observable, ObservedValueOf } from "rxjs";
import { AudioContextService } from "src/app/services/audio-context/audio-context.service";

@Component({
	selector: "app-audio-setup",
	templateUrl: "./audio-setup.component.html",
	styleUrls: ["./audio-setup.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class AudioSetupComponent {
	@Output() complete = new EventEmitter();
	private audioContext: AudioContext;
	preCallSettingsForm: UntypedFormGroup;
	currentVolume: number = 0;
	videoDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
	audioDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
	outputDevicesList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
	isMediaError = false;
	micIsWorking = false;
	videoIsWorking = false;
	isAudioOutputWorking = false;
	public isFirefox: boolean;
	public stream;
	public audioSettings = "audioInput";
	microphone: MediaStreamAudioSourceNode;
	analyser: AnalyserNode;
	javascriptNode: ScriptProcessorNode;

	public showOutputSettings() {
		this.audioSettings = "audioOutput";
	}

	onCompleted(): void {
		this.complete.emit();
	}

	public controls = {
		videoInputDeviceId: new UntypedFormControl(null, [Validators.required]), // videoInputDevices
		audioInputDeviceId: new UntypedFormControl(null, [Validators.required]), // audioInputDevices
		audioOutputDeviceId: new UntypedFormControl(null, [Validators.required]) // audioOutputDevices
	};

	constructor(
		private ngZone: NgZone,
		private mediaDevicesService: MediaDevicesService,
		private platformDetectorService: PlatformDetectorService,
		private audioContextService: AudioContextService
	) {
		this.preCallSettingsForm = new UntypedFormGroup({
			...this.controls
		});
	}

	async ngOnInit() {
		var constraints = { audio: true, video: true };
		this.updateDeviceList();
		try {
			this.stream = await navigator.mediaDevices.getUserMedia(
				constraints
			);
		} catch (e) {
			this.isMediaError = true;
		}
		this.initAudioAnalyser(this.stream);
		this.isFirefox = this.platformDetectorService.isFirefox();
		const mediaProperies =
			MediaProperiesUtil.readMediaProperiesFromStorage();

		const audioInpDevInfo = this.mediaDevicesService.findMediaDeviceInfo(
			this.audioDeviceList,
			mediaProperies.audioInpDev
		);

		if (this.audioDeviceList.length > 0) {
			const audioInpDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioDeviceList,
					mediaProperies.audioInpDev
				);
			this.controls.audioInputDeviceId.setValue(
				!!audioInpDevInfo ? audioInpDevInfo.deviceId : null,
				{ emitEvent: false }
			);
		} else {
			this.controls.audioInputDeviceId.disable({ emitEvent: false });
		}

		if (this.outputDevicesList.length > 0) {
			const audioOutDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.outputDevicesList,
					mediaProperies.audioOutDev
				);
			this.controls.audioOutputDeviceId.setValue(
				audioOutDevInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.controls.audioOutputDeviceId.disable({ emitEvent: false });
		}

		if (this.videoDeviceList.length > 0) {
			const videoDeviceInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.videoDeviceList,
					mediaProperies.videoInpDev
				);
			this.controls.videoInputDeviceId.setValue(
				videoDeviceInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.controls.videoInputDeviceId.disable({ emitEvent: false });
		}
	}

	private updateDeviceList(): Observable<
		ObservedValueOf<Promise<MediaDeviceInfo[]>>
	> {
		const deviceIds = from(this.mediaDevicesService.enumerateDevices(true));
		deviceIds.subscribe((ids: MediaDeviceInfo[]) => {
			this.videoDeviceList =
				this.mediaDevicesService.getDeviceVideoInput(ids);
			this.audioDeviceList =
				this.mediaDevicesService.getDeviceAudioInput(ids);
			this.outputDevicesList =
				this.mediaDevicesService.getDeviceAudioOutput(ids);
		});
		return deviceIds;
	}

	private initAudioAnalyser(stream: MediaStream): void {
		if (!this.audioContext) {
			this.audioContext = this.audioContextService.getAudioContext();
		}
		// Disconnect existing nodes if they exist
		if (this.microphone) {
			this.microphone.disconnect();
		}
		if (this.analyser) {
			this.analyser.disconnect();
		}
		if (this.javascriptNode) {
			this.javascriptNode.disconnect();
		}

		// Create new media stream source and analyser nodes
		this.microphone = this.audioContext.createMediaStreamSource(stream);
		this.analyser = this.audioContext.createAnalyser();
		this.javascriptNode = this.audioContext.createScriptProcessor(
			2048, 1, 1
		);

		// Configure the analyser node
		this.analyser.smoothingTimeConstant = 0.8;
		this.analyser.fftSize = 1024;

		// Reconnect the nodes with the new stream
		this.microphone.connect(this.analyser);
		this.analyser.connect(this.javascriptNode);
		this.javascriptNode.connect(this.audioContext.destination);

		// Listen to audioprocess events
		this.javascriptNode.onaudioprocess = () => {
			const array = new Uint8Array(this.analyser.frequencyBinCount);
			this.analyser.getByteFrequencyData(array);
			const values = array.reduce((sum, current) => {
				return sum + current;
			}, 0);

			this.ngZone.run(() => {
				this.currentVolume = values / array.length;
			});
		};
	}

	public async testAudio() {
		const audioEnter = new Audio("/assets/EnterSound.mp3");

		if (
			this.platformDetectorService.isSafari() ||
			this.platformDetectorService.isFirefox()
		) {
			try {
				await audioEnter.play();
			} catch (error) {
				console.log("Error playing audio:", error);
			}
		} else {
			await (audioEnter as any).setSinkId(
				this.controls.audioOutputDeviceId.value
			);
			await audioEnter.play();
		}
	}

	public async doChangeAudioOutputDevice(
		audioOutputDeviceId: string
	): Promise<void> {
		await this.writeMediaProperies();
	}

	public async doChangeAudioInputDevice(): Promise<void> {
		this.writeMediaProperies();
		await this.changeMediaSource();
	}

	public async updateAudioSettings(): Promise<void> {
		this.writeMediaProperies();
		await this.changeMediaSource();
	}

	private writeMediaProperies(): void {
		const videoInputDevice = this.controls.videoInputDeviceId.value;
		const audioInputDevice = this.controls.audioInputDeviceId.value;
		const audioOutputDevice = this.controls.audioOutputDeviceId.value;

		console.log(audioInputDevice);
		MediaProperiesUtil.writeVideoInputDeviceToStorage(videoInputDevice);
		MediaProperiesUtil.writeAudioInputDeviceToStorage(audioInputDevice);
		MediaProperiesUtil.writeAudioOutputDeviceToStorage(audioOutputDevice);
	}

	private async changeMediaSource(): Promise<void> {
		if (this.stream) {
			this.stream.getTracks().forEach((track) => {
				track.stop();
				this.stream.removeTrack(track);
			});
		}
		this.stream = null;
		this.isMediaError = false;
		this.stream = await navigator.mediaDevices.getUserMedia({
			audio: { deviceId: this.controls.audioInputDeviceId.value }
		});
		this.initAudioAnalyser(this.stream);
	}

	async ngOnDestroy() {
		if (this.stream) {
			this.stream.getTracks().forEach((track) => {
				track.stop();
				this.stream.removeTrack(track);
			});
		}
		this.audioContextService.closeAudioContext();
	}
}
