import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, OnDestroy, Input, HostBinding, Output, EventEmitter
} from '@angular/core';
import { SoundPlayer } from 'src/app/lib-sound-player/sound-player';
import { SoundPlayerUtil } from 'src/app/lib-sound-player/sound-player.util';

const BPM_MIN = 20;
const BPM_INIT = 120;
const BPM_MAX = 260;
const BPM_STEP = 1;
const PORTIONS_MIN = 2;
const PORTIONS_MAX = 12;

enum NumberOfNotes {
  one = 1,
  two = 2,
  three = 3,
  four = 4
}

@Component({
  selector: 'app-metronome',
  templateUrl: './metronome.component.html',
  styleUrls: ['./metronome.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetronomeComponent implements OnInit, OnDestroy {
  @Input()
  public isMobile = false;
  @Input()
  public isStress = false;
  @Input()
  public bpmMin = BPM_MIN;
  @Input()
  public bpmMax = BPM_MAX;
  @Input()
  public bpmStep = BPM_STEP;
  @Input()
  public bpmValue = BPM_INIT;
  @Input()
  public numberOfNotes = NumberOfNotes;

  @Output()
  readonly clickClose: EventEmitter<void> = new EventEmitter();

  public numberNotes: NumberOfNotes = NumberOfNotes.one.valueOf();
  public portions: boolean[] = [false, false];

  private audioContext: AudioContext;
  private firstSoundPlayer: SoundPlayer;
  private second1SoundPlayer: SoundPlayer;
  private second2SoundPlayer: SoundPlayer;
  private second3SoundPlayer: SoundPlayer;
  private stressSoundPlayer: SoundPlayer;
  private worker: Worker;
  private cyclingInterval = 0;
  private cyclingMiniInterval = 0;
  private nextMiniInterval: NodeJS.Timeout | null = null;

  private isPlay = false;
  private noteIdx = 0;
  private portionIdx = 0;

  public get isAudioPlay(): boolean { return this.isPlay; }
  public set isAudioPlay(value: boolean) {  }

  @HostBinding('class.is-mobile')
  get isClassMobile(): boolean { return this.isMobile; }

  constructor(
    private changeDetector: ChangeDetectorRef,
  ) {
    (window as any).AudioContext = ((window as any).AudioContext || (window as any).webkitAudioContext);
    this.audioContext = new ((window as any).AudioContext)();
    this.firstSoundPlayer = new SoundPlayer(this.audioContext);
    this.second1SoundPlayer = new SoundPlayer(this.audioContext);
    this.second2SoundPlayer = new SoundPlayer(this.audioContext);
    this.second3SoundPlayer = new SoundPlayer(this.audioContext);
    this.stressSoundPlayer = new SoundPlayer(this.audioContext);
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../utils/metronome.worker.ts', import .meta.url), { type: 'module' });
    } else {
      throw new Error('Web Workers are not supported in this environment.');
    }
    this.worker.onmessage = this.workerReceived;
  }

  async ngOnInit() {
    this.setting();
    // Setting first SoundPlayer
    const firstAudioBuffer = await SoundPlayerUtil.fetchAndCreateBufferSource(
      this.audioContext, '/assets/media/metronome/metronome1.wav');
    await this.firstSoundPlayer.setAudioBuffer(firstAudioBuffer);
    // Setting second SoundPlayer
    const secondAudioBuffer = await SoundPlayerUtil.fetchAndCreateBufferSource(
      this.audioContext, '/assets/media/metronome/metronome2.wav');
    this.second1SoundPlayer.setAudioBuffer(secondAudioBuffer);
    this.second2SoundPlayer.setAudioBuffer(secondAudioBuffer);
    this.second3SoundPlayer.setAudioBuffer(secondAudioBuffer);
    // Setting stress SoundPlayer
    const stressAudioBuffer = await SoundPlayerUtil.fetchAndCreateBufferSource(
      this.audioContext, '/assets/media/metronome/metronome-stress.wav');
    this.stressSoundPlayer.setAudioBuffer(stressAudioBuffer);
  }

  ngOnDestroy(): void {
    this.stopPlaying();
    this.firstSoundPlayer.OnDestroy();
    this.second1SoundPlayer.OnDestroy();
    this.second2SoundPlayer.OnDestroy();
    this.second3SoundPlayer.OnDestroy();
    this.stressSoundPlayer.OnDestroy();
  }

  // ** Public API **

  public changeBpmValue(newBpmValue: number): void {
    if (BPM_MIN <= newBpmValue && newBpmValue <= BPM_MAX && this.bpmValue !== newBpmValue) {
      this.bpmValue = newBpmValue;
      this.settingAndStopStartPlay();
      this.changeDetector.markForCheck();
    }
  }

  public clickNotes(numberNotes: NumberOfNotes): void {
    if (!!numberNotes) {
      this.numberNotes = numberNotes;
      this.settingAndStopStartPlay();
      this.changeDetector.markForCheck();
    }
  }

  public changePortion(newValue: number): void {
    const current = this.portions.length;
    if (PORTIONS_MIN <= newValue && newValue <= PORTIONS_MAX && current !== newValue) {
      this.portions.length = newValue;
      this.changeDetector.markForCheck();
    }
  }

  public clickPlayAudio(isPlay: boolean): void {
    if (isPlay) {
      this.startPlaying();
    } else {
      this.stopPlaying();
    }
  }

  public close(): void {
    this.stopPlaying();
    this.clickClose.emit();
  }

  // ** Private API **

  private updatePortionIdx(): void {
    if (this.portionIdx < this.portions.length) {
      this.portions[this.portionIdx] = false;
    }
    this.portionIdx++;
    if (this.portionIdx >= this.portions.length) {
      this.portionIdx = 0;
    }
    this.portions[this.portionIdx] = true;
  }

  private sleep(ms = 100) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private startPlaying(): void {
    this.stopMainInterval();
    this.noteIdx = 0;
    for (let idx = 0; idx < this.portions.length; idx++) { this.portions[idx] = false; }
    this.portionIdx = this.portions.length - 1;
    this.isPlay = true;
    this.worker.postMessage({ command: 'start', interval: this.cyclingInterval });
    this.workerReceived({ data: {}});
    this.changeDetector.markForCheck();
  }

  private stopPlaying(): void {
    if (this.isPlay) {
      this.isPlay = false;
      this.stopMainInterval();
      this.clearMiniInterval();
      this.second1SoundPlayer.stop();
      this.second2SoundPlayer.stop();
      this.second3SoundPlayer.stop();
    }
  }

  private settingAndStopStartPlay(): void {
    const currentIsPlay = this.isPlay;
    if (currentIsPlay) {
      this.stopPlaying();
    }
    this.setting();
    if (currentIsPlay) {
      this.startPlaying();
    }
  }
  private setting(): void {
    this.cyclingInterval = (60000 / this.bpmValue);
    const count = this.numberNotes.valueOf();
    this.cyclingMiniInterval = this.cyclingInterval / count;
  }

  private workerReceived = ({ data }) => {
    this.playFirstSound();
    this.changeDetector.markForCheck();
    // Get the number of sounds.
    const count = this.numberNotes.valueOf();
    if (1 < count) {
      this.second1SoundPlayer.play(1 * this.cyclingMiniInterval / 1000, 0, 0.05);
    }
    if (2 < count) {
      this.second1SoundPlayer.play(2 * this.cyclingMiniInterval / 1000, 0, 0.05);
    }
    if (3 < count) {
      this.second1SoundPlayer.play(3 * this.cyclingMiniInterval / 1000, 0, 0.05);
    }
  }

  private clearMiniInterval(): void {
    if (!this.nextMiniInterval) {
      clearInterval(this.nextMiniInterval);
      this.nextMiniInterval = null;
    }
  }

  private playFirstSound(): void {
    this.updatePortionIdx();
    if (this.isStress && this.portionIdx === 0) {
      this.stressSoundPlayer.play(0, 0, 0.05);
    } else {
      this.firstSoundPlayer.play(0, 0, 0.05);
    }
  }

  private stopMainInterval() {
    this.worker.postMessage({ command: 'stop' });
  }

}
