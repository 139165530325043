import { Component } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule} from '@ngx-translate/core';
import { ProfileService } from '../profile/profile.service';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-availabilities-tab',
  standalone: true,
  imports: [MatTabsModule,SharedModule,TranslateModule,CommonModule ],
  templateUrl: './availabilities-tab.component.html',
  styleUrl: './availabilities-tab.component.scss'
})
export class AvailabilitiesTabComponent {
    constructor(private profileService:ProfileService) { 
}

    isBetaTester = false;

    ngOnInit() {
       this.profileService.get().subscribe((profile) => {
            if (!profile) {
                return;
            }
            else if (profile.betaTesting) {
                this.isBetaTester = profile.betaTesting
            }
            else return
        })   
    }

}
