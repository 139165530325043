import { Component } from "@angular/core";
import { StripePricingTableComponent } from "../stripe-pricing-table/stripe-pricing-table.component";
import { ActivatedRoute } from "@angular/router";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ReviewCarouselComponent } from "../carousel/review-carousel.component";
import { PopupService } from "../services/popup/popup.service";
import { CountdownComponent } from "../countdown/countdown.component";
import { StorageUtil } from "../utils/storage.util";
import { getSubDomain } from "../helpers/url";
import { GA4Service } from "../services/ga4.service";

const RATINGS = [
	{
		rating: 5,
		headline: "Ich konnte immer super flexibel meine Termine ausmachen",
		text: "Ich konnte immer super flexibel meine Termine ausmachen und der Online-Unterricht funktioniert für mich sehr gut. :)",
		name: "Umali"
	},
	{
		rating: 5,
		headline:
			"Ich habe tollen Gesangsunterricht bei Julianna über Sirius gebucht.",
		text: "Ich habe tollen Gesangsunterricht bei Julianna über Sirius gebucht. Wirklich eine gute Musikschule. Kann ich sehr weiterempfehlen.",
		name: "Valentin M"
	},
	{
		rating: 5,
		headline: "Ist fantastich wenn man anfangen will zu singen",
		text: "Ist fantastich wenn man anfangen will zu singen, sehr beginnerfreundlich, preis ist auch gut",
		name: "yeeesi"
	},
	{
		rating: 5,
		headline: "Die Kommunikation mit Sirius ist einwandfrei",
		text: "Die Kommunikation mit Sirius ist einwandfrei, mit meinem Lehrer bin ich auch sehr zufrieden. Kann ich nur weiterempfehlen!",
		name: "Moritz Voss"
	}
];

const FAQS = {
	thomann: {
		questions: [
			"Was ist der Thomann-Vorteil?",
			"Wie kann ich meine Unterrichtsstunde verschieben?",
			"Kann ich meine Lehrerin oder meinen Lehrer wechseln?",
			"Gilt der Vorteil nur für Thomann-Kunden?",
			"Wie sind die Kündigungsbedingungen?"
		],
		answers: [
			"Mit deinem Thomann-Code bekommst du zusätzlich zu deinem Unterrichtspaket 2x 45min Unterricht geschenkt.",
			"Absage / Verschieben des Unterrichts sind bis 24 Std. vor Unterrichtsbeginn möglich. Nachholtermine bei Unterrichtsausfall aufgrund von Krankheit, Urlaub, usw. kannst du bei SIRIUS ganz einfach online buchen.",
			"Der Unterricht erfolgt bei der Lehrkraft mit der du deine Probestunde hattest. Bei Bedarf ist ein Lehrerwechsel innerhalb des Unterrichtspakets jederzeit möglich.",
			"Ja, du bekommst 2 Unterrichts nur mit deinem persönlichen Thomann-Code, den du per E-Mail von Thomann erhalten hast. Das Angebot gilt exklusiv für Thomann-Kunden.",
			"Die Kündigungsfrist beträgt 1 Monat vor Ablauf deines Unterrichtpakets. Weitere Informationen findest du in den AGBs."
		]
	}
};
@Component({
	selector: "app-checkout-area",
	standalone: true,
	imports: [
		StripePricingTableComponent,
		CommonModule,
		CdkAccordionModule,
		ReviewCarouselComponent,
		CountdownComponent
	],
	templateUrl: "./checkout-area.component.html",
	styleUrl: "./checkout-area.component.scss"
})
export class CheckoutAreaComponent {
	country = "";
	userId: string;
	pricingTableId;
	publishKey;
	externalLink;
	referrerUrl: string;
	previousUrl: string;
	affiliate: string;
	teacher: string;
	userName: string;
	endDate: Date;
	ratings = RATINGS;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private popupService: PopupService,
		private ga4: GA4Service
	) {
		if (!getSubDomain()) {
			this.ga4.insertAnalyticsScript();
			this.ga4.setUpAnalytics();
		}
	}

	// Production Table

	germanPricingTableId = "prctbl_1OyBOVIL4ElhaLdYg9n0sT9H";
	germanPublishKey =
		"pk_live_51I5tSKIL4ElhaLdYl6dj6fpOpgqUTp6ZXhkZYDnf19nW9isHvfbHvh7ktcM9o5g1P0XdwztH5asfPiks7MBe0QCU009yFHk3HK";

	swissPricingTableId = "prctbl_1P1l9VIL4ElhaLdYPaBevKnf";
	swissPublishKey =
		"pk_live_51I5tSKIL4ElhaLdYl6dj6fpOpgqUTp6ZXhkZYDnf19nW9isHvfbHvh7ktcM9o5g1P0XdwztH5asfPiks7MBe0QCU009yFHk3HK";

	thomanGermanPricingTableId = "prctbl_1P4JSTIL4ElhaLdY3vMBo23p";
	thomanGermanPublishKey =
		"pk_live_51I5tSKIL4ElhaLdYl6dj6fpOpgqUTp6ZXhkZYDnf19nW9isHvfbHvh7ktcM9o5g1P0XdwztH5asfPiks7MBe0QCU009yFHk3HK";

	thomannSwissPricingTableId = "prctbl_1P4JozIL4ElhaLdYvuahcpmp";
	thomannSwissPublishKey =
		"pk_live_51I5tSKIL4ElhaLdYl6dj6fpOpgqUTp6ZXhkZYDnf19nW9isHvfbHvh7ktcM9o5g1P0XdwztH5asfPiks7MBe0QCU009yFHk3HK";

	// Test Table
	// germanPricingTableId = "prctbl_1OyEVAIL4ElhaLdYjCbXESZt";
	// germanPublishKey =
	// 	"pk_test_51I5tSKIL4ElhaLdYWARVcUmYrtFQ15jan1Uh97eP9q4BFRyuWSjDOnikLAQ474RMV1DiDGWLQ9ReEcQwxkuh91Hm00PzHmHAwR";
	// swissPricingTableId = "prctbl_1OyEVAIL4ElhaLdYjCbXESZt";
	// swissPublishKey =
	// 	"pk_test_51I5tSKIL4ElhaLdYWARVcUmYrtFQ15jan1Uh97eP9q4BFRyuWSjDOnikLAQ474RMV1DiDGWLQ9ReEcQwxkuh91Hm00PzHmHAwR";

	// default faqs
	questions = [
		"Welches Paket passt zu mir?",
		"Wie kann ich meine Unterrichtsstunde verschieben?",
		"Wie sind die Kündigungsbedingungen?",
		"Kann ich meine Lehrerin oder meinen Lehrer  wechseln?",
		"Kann ich das Abbuchungsdatum ändern?",
		"Werden meine Zahlungen sicher abgewickelt?"
	];
	// default faqs
	answers = [
		"Musikunterricht braucht Regelmäßigkeit. Wir empfehlen 18 Einheiten pro Halbjahr, da du unserer Erfahrung nach, so den größten Lerneffekt erziehlst und nachhaltig lernst.",
		"Absage / Verschieben des Unterrichts sind bis 24 Std. vor Unterrichtsbeginn möglich. Nachholtermine bei Unterrichtsausfall aufgrund von Krankheit, Urlaub, usw. kannst du bei SIRIUS ganz einfach online buchen.",
		"Die Kündigungsfrist beträgt 1 Monat vor Ablauf deines Unterrichtpakets. Weitere Informationen findest du in den AGBs.",
		"Der Unterricht erfolgt bei der Lehrkraft mit der du deine Probestunde hattest. Bei Bedarf ist ein Lehrerwechsel innerhalb des Unterrichtspakets jederzeit möglich.",
		"Wenn du das Abbuchungsdatum für dein Unterrichtspaket beispielsweise auf den 20. Des Monats ändern möchtest, schreib uns bitte eine Mail mit dem Betreff ‘Abbuchungsdatum’ an mail@sirius.video.",
		"Zahlungen auf der SIRIUS Plattform werden von unserem Partner Stripe abgewickelt, einer der bekanntesten und sichersten Zahlungsanbieter weltweit. Über diesen kann man via Kreditkarte oder SEPA Lastschrift sicher zahlen."
	];

	expandedIndex = 0;

	ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params["externalLink"] == "false") {
				this.externalLink = false;
			} else {
				this.externalLink = true;
			}
		});

		const lessonEndDetails =
			StorageUtil.readLessonEndTimeFromLocalStorage();

		if (lessonEndDetails) {
			this.endDate = new Date(
				lessonEndDetails.endTime + 24 * 60 * 60 * 1000
			);
			const isInPast = this.endDate < new Date();
			if (isInPast) {
				this.endDate = null;
			}
			this.teacher = lessonEndDetails.teacherName;
		}
		if (!this.teacher)
			this.teacher = sessionStorage.getItem("teacherName") || null;
		this.userName = sessionStorage.getItem("userName") || null;

		if (!this.externalLink) {
			this.popupService.openCheckoutDialog(this.teacher);
		}

		// for external link

		// Fetch userId
		this.userId = this.route.snapshot.queryParamMap.has("userId")
			? this.route.snapshot.queryParamMap.get("userId")
			: localStorage.getItem("userId");
		// Fetch country
		this.country = this.route.snapshot.queryParamMap.has("ctr")
			? this.route.snapshot.queryParamMap.get("ctr")
			: localStorage.getItem("ctr");
		// Fetch affiliate
		this.affiliate = this.route.snapshot.queryParamMap.has("aff")
			? this.route.snapshot.queryParamMap.get("aff")
			: localStorage.getItem("aff");
		// th = thomann
		if (this.country === "germany" && this.affiliate === "th") {
			this.pricingTableId = this.thomanGermanPricingTableId;
			this.publishKey = this.thomanGermanPublishKey;
		} else if (this.country === "germany") {
			this.pricingTableId = this.germanPricingTableId;
			this.publishKey = this.germanPublishKey;
		} else if (this.country === "switzerland" && this.affiliate === "th") {
			this.pricingTableId = this.thomannSwissPricingTableId;
			this.publishKey = this.thomannSwissPublishKey;
		} else if (this.country === "switzerland") {
			this.pricingTableId = this.swissPricingTableId;
			this.publishKey = this.swissPublishKey;
		} else {
			this.pricingTableId = this.germanPricingTableId;
			this.publishKey = this.germanPublishKey;
		}

		if (this.affiliate === "th") {
			this.questions = FAQS.thomann.questions;
			this.answers = FAQS.thomann.answers;
		}
	}
}
