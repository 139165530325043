import { Component, EventEmitter, Output } from "@angular/core";
import { NgClass } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Profile } from "@data-types/profile.types";
import { TeachersService } from "src/app/services/teachers.service";

@Component({
	selector: "app-multiteacher-dropdownlist",
	standalone: true,
	imports: [
		MatSelectModule,
		MatFormFieldModule,
		MatOptionModule,
		FormsModule,
		ReactiveFormsModule,
		NgClass
	],
	templateUrl: "./multiteacher-dropdownlist.component.html",
	styleUrl: "./multiteacher-dropdownlist.component.scss"
})
export class MultiteacherDropdownlistComponent {
	@Output() selectedTeacher = new EventEmitter<any>();
	private isStudent: boolean = false;
	private profile: Profile;
	public lessonPackage;
	public teachersList;
	public teacherControl = new FormControl();

	constructor(
		private authenticationService: AuthenticationService,
		private teachersService: TeachersService
	) {}

	async ngOnInit(): Promise<void> {
		this.profile = await this.authenticationService.profile;
		this.isStudent = this.checkSubRole(this.profile.subrole);

		if (this.isStudent) {
			this.getAllTeachers(this.profile.userId);
		}
	}

	async getAllTeachers(userId: string) {
		await this.teachersService.getTeachers(userId).subscribe(({ list }) => {
			this.teachersList = list;
			if (this.teachersList && this.teachersList.length > 0) {
				this.teacherControl.setValue(this.teachersList[0]);
				this.selectingTeacher(this.teachersList[0]);
			}
		});
	}

	selectingTeacher(teacher) {
		this.selectedTeacher.emit(teacher);
	}

	checkSubRole(subRole: number): boolean {
		return subRole > 3;
	}
}
