import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { getSubDomain } from '../helpers';
declare let Beacon: any ;

declare function helpdeskBeaconGerman(): void;
declare function helpdeskBeaconEnglish(): void;

@Injectable({
  providedIn: 'root'
})

export class HelpScoutService {
  
constructor(private translateService: TranslateService, private authenticationService: AuthenticationService) {  }
  
 showBeacon(): void {
    const lang = this.translateService.currentLang;
    if (lang === "deu") {
        helpdeskBeaconGerman();
      } else {
        helpdeskBeaconEnglish();
      }
  }

  async showBeaconWithId(): Promise<void> {
    let profile = await this.authenticationService.profile;
    const subrole = await this.authenticationService.getSubrole();
    const lang = this.translateService.currentLang;
    if (lang === "deu") {
      helpdeskBeaconGerman();
    } else {
      helpdeskBeaconEnglish();
    }
    if (!getSubDomain()) {
      Beacon('open');
      Beacon('identify', {
        name: profile.firstName + ' ' + profile.lastName,
        email: profile.email,
        language: this.translateService.currentLang,
        subscription: profile.subscription.type,   
        user_role: subrole
      });
      Beacon('close');
    }
  }

destroyBeacon(): void {
    Beacon('destroy');
}

}
