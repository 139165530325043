import { Component, OnDestroy, OnInit } from "@angular/core";
import { LessonsOverviewMenuComponent } from "./components/lessons-overview-menu/lessons-overview-menu.component";
import { LessonsOverviewTableComponent } from "./components/lessons-overview-table/lessons-overview-table.component";
import { EmptyListContainerComponent } from "../components/util/empty-list-container/empty-list-container.component";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { CommonModule } from "@angular/common";
import { StudentsService } from "../students/students.service";
import { LessonsService } from "../services/lessons/lessons.service";
import { LessonsCommunicationService } from "./services/lessons-communication.service";
import { forkJoin, fromEvent, of, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { TranslateModule } from "@ngx-translate/core";

export interface StudentInfo {
	id: string;
	name: string;
	email: string;
	subrole: number;
	activationStatus: string;
	instruments: string[];
	genres: string[];
	favoriteSong: string | null;
	notes: string | null;
	ageGroup: string | null;
}

@Component({
	selector: "app-lessons-overview",
	standalone: true,
	imports: [
		CommonModule,
		LessonsOverviewMenuComponent,
		LessonsOverviewTableComponent,
		EmptyListContainerComponent,
		TranslateModule
	],
	templateUrl: "./lessons-overview.component.html",
	styleUrl: "./lessons-overview.component.scss"
})
export class LessonsOverviewComponent implements OnInit, OnDestroy {
	students: Array<StudentInfo> = [];
	isMobile = false;
	selectedStudent: any;
	userId: string;
	timeZone: string;
	page: number = 1;
	limit = "10";
	size: number;
	isLoading: boolean = false;
	screenWidth: number;
	count: number;
	pages: number;
	resizeSubscription: Subscription;

	constructor(
		private authenticationService: AuthenticationService,
		private studentsService: StudentsService,
		private lessonsComunication: LessonsCommunicationService,
		private lessonsService: LessonsService
	) {}

	async ngOnInit() {
		this.init();
		this.startScreenWidthListener();
	}

	ngOnDestroy() {
		if (this.resizeSubscription) {
			this.resizeSubscription.unsubscribe();
		}
	}

	async init() {
		this.isLoading = true;
		const profile = await this.authenticationService.profile;
		this.userId = profile.userId;
		this.timeZone = profile.timezone;

		this.studentsService
			.getStudents(this.userId, this.page, this.limit)
			.pipe(
				switchMap((data: any) =>
					this.getStudentsWithLessonPackages(data)
				),
				catchError((error) => {
					this.handleError(error);
					return of(null);
				})
			)
			.subscribe((data) => {
				if (data) {
					this.processStudentData(data);
				}
				this.isLoading = false;
			});
	}

	private getStudentsWithLessonPackages(data: any) {
		const { list } = data;

		const lessonPackageObservables = list.map((student: any) =>
			this.lessonsService.getLessonPackage(student.email).pipe(
				map((lessonPackage) => ({
					...student,
					lessonPackage: lessonPackage
				})),
				catchError((error) => {
					console.error(
						`Failed to get lesson package for ${student.email}`,
						error
					);
					return of({
						...student
					});
				})
			)
		);

		return forkJoin(lessonPackageObservables).pipe(
			map((studentsWithPackages: any[]) => {
				const filteredStudents = studentsWithPackages.filter(
					(student) => student?.lessonPackage?.active === true
				);
				return {
					...data,
					list: filteredStudents
				};
			})
		);
	}

	private processStudentData(data: any) {
		const { count, page, pages, list } = data;
		this.students = list;
		this.page = page;
		this.count = count;
		this.pages = pages;
		this.size = count;

		if (this.students.length > 0) {
			this.handleFirstStudent(this.students[0]);
		}
	}

	private handleError(error: any) {
		if (error.status === 404) {
			this.students = null;
		}
		this.isLoading = false;
		console.error("An error occurred:", error);
	}

	getStudentsList() {
		this.isLoading = true;
		return this.studentsService
			.getStudents(this.userId, this.page, this.limit)
			.pipe(
				switchMap((data: any) =>
					this.getStudentsWithLessonPackages(data)
				),
				catchError((error) => {
					this.handleError(error);
					return of(null);
				}),
				map((data) => {
					this.processStudentData(data);
					this.isLoading = false;
				})
			);
	}

	handleFirstStudent(firstStudent): void {
		this.selectedStudent = firstStudent;
		this.lessonsComunication.updateStudentData(this.selectedStudent);
	}

	startScreenWidthListener(): void {
		this.resizeSubscription = fromEvent(window, "resize")
			.pipe(
				map(() => window.innerWidth),
				startWith(window.innerWidth)
			)
			.subscribe((width) => {
				this.isMobile = width <= 767.9;
			});
	}
	previousPage() {
		if (this.page > 1) {
			this.page--;
			this.getStudentsList().subscribe();
		}
	}

	nextPage() {
		if (this.page < this.pages) {
			this.page++;
			this.getStudentsList().subscribe();
		}
	}
}
