import { Component,Input,OnInit,ChangeDetectorRef, OnDestroy,Output,EventEmitter} from '@angular/core';
import { IconService } from '@shared/services/icon.service';
import {PlatformDetectorService} from '../../services/platform-detector/platform-detector.service';

@Component({
  selector: 'app-practice-room',
  templateUrl: './practice-room.component.html',
  styleUrls: ['./practice-room.component.scss']
})

export class PracticeRoomComponent {

  public changeIsPortrait(): void {
    if (this.isBrowserSafari) {
      this.renderingComponent();
    }
  }

  public isMobile: boolean;
  public isPortrait: boolean;
  public isTuner = true;
  public isMetronome = true;
  public isDestroy: boolean;
  public isPiano = true;

  isBrowserSafari: boolean = false;
  @Output() readonly toggleTuner: EventEmitter<boolean> = new EventEmitter();


  public renderingComponent(): void {
    if (this.isDestroy) { return; }
    this.changeDetector.detectChanges();
    if (this.isBrowserSafari) {
      setTimeout(() => {
        this.changeDetector.detectChanges();
      }, 50);
    }
  }

  doToggleTuner(isTuner: boolean): void {
    if (this.isTuner !== isTuner) {
      this.isTuner = isTuner;
    }
  }

  doToggleMetronome(isMetronome: boolean): void {
    if (this.isMetronome !== isMetronome) {
      this.isMetronome = isMetronome;
    }
  }

  doTogglePiano(isPiano: boolean): void {
    if (this.isPiano !== isPiano) {
      this.isPiano = isPiano;
    }
  }

  public isMobileWidth():boolean{
    if (window.innerWidth < 768) {
      return true
    }
    else {
      return false
    }
  }

  public tunerClose(): void {
    this.isTuner = false;
  }

  public metronomeClose(): void {
    this.isMetronome = false;
  }


constructor(iconService:IconService, private platformDetectorService:PlatformDetectorService, protected changeDetector: ChangeDetectorRef) {
  const deviceInfo = this.platformDetectorService.deviceInfo;
  this.isBrowserSafari = (deviceInfo.browser === 'Safari');
  // Called first time before the ngOnInit()
}

@Input() theme;
ngOnInit() {
  this.isMobile = this.platformDetectorService.isMobile();
  this.isPortrait = this.platformDetectorService.isOrientationPortrait();
}

ngOnDestroy(){
  this.isDestroy = true;
}

}
