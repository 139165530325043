import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { ProfileService } from "../profile.service";
import { Profile } from "../../types/profile.types";
import { confirmPasswordValidator } from "../../validators/confirm-password";
import { Timezone } from "../../models/timezone";
import { Language, LanguagesList } from "../../models/language";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../services/languages/language.service";
import { Tariff, TariffUtil } from "../../types/tariff-constants";
import {
	DISALLOW_TRAILING_SPACES,
	TEXT_NAME_MAX_LENGTH,
	PASS_VALIDATION_PATTERN
} from "../../constants/generic.constants";

import { AuthenticationService } from "../../services/authentication/authentication.service";
import { EMAIL_VALIDATION_REG } from "../../lib-core/constants/constants";
import { Title } from "@angular/platform-browser";
import { StripeService } from "../../services/stripe/stripe.service";
import { HelpScoutService } from "../../services/help-scout-service.service";
import {
	SPOKEN_LANGUAGES,
	// GENRES,
	GENDERS,
	// INSTRUMENTS,
	VAT_STATUS,
	MIN_DATE,
	MAX_DATE,
	BETA_TESTING_OPTIONS
} from "../profile.constants";
import { getSubDomain } from "src/app/helpers";
import { ActivatedRoute } from "@angular/router";
import { SubRole } from "src/app/constants/subroles-constants";
import { Resources } from "src/app/services/access-control/resources.constants";
import { createPopup } from "@typeform/embed";

@Component({
	selector: "app-profile-new",
	templateUrl: "./profile-new.component.html",
	styleUrls: ["./profile-new.component.scss"]
})
export class ProfileNewComponent {
	public form: UntypedFormGroup;
	public isSchool: boolean;
	public timezones: Timezone[];
	public languages: Language[];
	public passwordUpdated = false;
	public tariffType: Tariff;
	public tariffLabel: string;
	public isTariffExpired: boolean;
	public isShowPassEmail: boolean;
	public isShowPass: boolean;
	public isShowNewPass: boolean;
	public isShowConfPass: boolean;
	public isSavingError: boolean;
	public isSavedMessage: boolean;
	public isShowPayoutMessage: boolean;
	public isShowPackageMessage: boolean;
	public isShowSubscriptionMessage: boolean;
	public tabs = [
		"personal",
		"personal",
		"musical",
		"payout",
		"notifications"
	];
	public isShowErrors: boolean;
	public Resources = Resources;
	public SubRole = SubRole;
	private profile: Profile;
	private selectedLanguage;
	private selectedLanguageIso;

	// Validators feedback values
	hasEnoughPassLength: boolean;
	hasNumberOrSpecial: boolean;
	isNotEmpty: boolean;
	disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;
	displayPassEmail = false;
	tab: string;
	@Input() subrole: SubRole;
	readonly Tariff = Tariff;

	gendersOptions = GENDERS;
	instrumentsOptions;
	genresOptions;
	languagesOptions = SPOKEN_LANGUAGES;
	vatOptions = VAT_STATUS;
	minDate = MIN_DATE;
	maxDate = MAX_DATE;
	betaTestingOptions = BETA_TESTING_OPTIONS;
	isOtherGender = false;
	numberOfLinks = 1;

	// for stopping autocomplete
	passwordHasBeenTouched = false;

	get timeZone(): string {
		if (Array.isArray(this.form.get("timezone").value)) {
			return this.form.get("timezone").value[0];
		}
		return this.form.get("timezone").value;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private profileService: ProfileService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private title: Title,
		private stripeService: StripeService,
		private helpScoutService: HelpScoutService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.route.url.subscribe((url) => {
			this.tab = url[0] ? url[0].path : "account";
		});
		this.initForm();
		this.isSchool = !!getSubDomain();

		this.loadLanguages()
			.then(() => {
				return this.loadTimezones();
			})
			.then(() => {
				this.loadProfile();
			})
			.catch((error) => {
				console.error("error loading data", error);
			});
		this.title.setTitle(
			this.translateService.instant("profile-page.seo-title")
		);
		this.helpScoutService.showBeacon();

		this.onLanguageChange;

		// this.loadInstrumentsAndGenres();

		// this.translateService.onLangChange.subscribe(() =>
		// 	this.loadInstrumentsAndGenres()
		// );
	}

	ngAfterViewInit() {
		// more anti-autocomplete
		this.form.patchValue(
			{
				account: {
					passwordGroup: {
						password: "",
						newPassword: "",
						confirmPassword: ""
					}
				}
			},
			{ emitEvent: false }
		);
		this.accountGroup
			.get("passwordGroup")
			.updateValueAndValidity({ emitEvent: false });
	}

	ngOnDestroy() {
		this.helpScoutService.destroyBeacon();
	}

	addLink() {
		this.numberOfLinks++;
	}

	handlePasswordFocus() {
		this.passwordHasBeenTouched = true;
	}

	private initForm(): void {
		this.form = this.fb.group({
			account: this.fb.group({
				firstName: [
					"",
					[
						Validators.required,
						Validators.maxLength(TEXT_NAME_MAX_LENGTH)
					]
				],
				lastName: [
					"",
					[
						Validators.required,
						Validators.maxLength(TEXT_NAME_MAX_LENGTH)
					]
				],
				emailGroup: this.fb.group({
					email: [
						"",
						[
							Validators.required,
							Validators.pattern(EMAIL_VALIDATION_REG),
							Validators.maxLength(TEXT_NAME_MAX_LENGTH)
						]
					],
					password: [""]
				}),
				passwordGroup: this.fb.group(
					{
						password: [""],
						newPassword: [
							"",
							[
								Validators.minLength(8),
								Validators.pattern(PASS_VALIDATION_PATTERN)
							]
						],
						confirmPassword: [
							"",
							[Validators.pattern(PASS_VALIDATION_PATTERN)]
						]
					},
					{ validators: confirmPasswordValidator }
				),
				language: ["", [Validators.required]],
				phoneNumber: ["", [Validators.required]],
				timezone: ["", [Validators.required]],
				betaTesting: ["", [Validators.required]]
			}),
			personal: this.fb.group({
				photo: ["", Validators.required],
				dob: ["", [Validators.required]],
				gender: ["", Validators.required],
				otherGender: [""],
				teacherIntroduction: ["", Validators.required],
				socials: this.fb.group({
					link1: [""],
					link2: [""],
					link3: [""],
					link4: [""],
					link5: [""]
				})
			}),
			musical: this.fb.group({
				// instruments: [[], Validators.required],
				// musicGenres: [[], Validators.required],
				languages: [[], Validators.required],
				teachingExperience: ["", Validators.required],
				studentPreferences: ["", Validators.required],
				preferenceComment: ["", Validators.required]
			}),
			payout: this.fb.group({
				taxNumber: ["", Validators.required],
				taxAddress: ["", Validators.required],
				vatStatus: ["", Validators.required],
				accountName: ["", Validators.required],
				iban: [
					"",
					[Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/)]
				],
				bic: [
					"",
					[
						Validators.required,
						Validators.pattern(/^[a-zA-Z0-9-]*$/),
						Validators.minLength(8),
						Validators.maxLength(11)
					]
				]
			})
		});

		this.form.get("payout")?.disable();

		// temporary hack
		if (
			this.subrole === SubRole.STUDENT_MATCHED ||
			this.subrole === SubRole.TEACHER_SELF
		) {
			this.form.removeControl("personal");
			this.form.removeControl("payout");
			const musicalGroup = this.form.get("musical") as UntypedFormGroup;
			const personalGroup = this.form.get("account") as UntypedFormGroup;
			personalGroup.removeControl("phoneNumber");
			personalGroup.removeControl("betaTesting");
			[
				"languages",
				"education",
				"studentPreferences",
				"bestMatch"
			].forEach((controlName) => {
				musicalGroup.removeControl(controlName);
			});

			// musicalGroup
			// 	.get("instruments")
			// 	.removeValidators(Validators.required);
			// musicalGroup
			// 	.get("musicGenres")
			// 	.removeValidators(Validators.required);
		}

		this.form
			.get("account.passwordGroup.newPassword")
			.valueChanges.subscribe(() => {
				this.updatePasswordValidity();
			});

		this.form.get("personal.gender")?.valueChanges.subscribe((value) => {
			this.isOtherGender = value === "other";
		});

		this.form
			.get("account.emailGroup.email")
			.valueChanges.subscribe((value) => {
				this.onEmailChange(value);
			});

		this.form
			.get("account.passwordGroup.newPassword")
			.valueChanges.subscribe((value) => {
				this.onPasswordChange(value);
			});
		this.form.get("account.language").valueChanges.subscribe((value) => {
			this.onLanguageChange(value);
		});
	}

	private onEmailChange(value): void {
		const prevEmail = this.authenticationService.profileInfo().email;
		if (value && value !== prevEmail) {
			this.togglePasswordFields(false);
			this.displayPassEmail = true;
			this.form
				.get("account.emailGroup.password")
				.setValidators([Validators.required]);
		} else {
			this.form.get("account.emailGroup.password").setValidators(null);
			this.displayPassEmail = false;
			this.togglePasswordFields(true);
		}

		this.form
			.get("account.emailGroup.password")
			.updateValueAndValidity({ emitEvent: false });
	}

	private onPasswordChange(value): void {
		if (value) {
			if (this.toggleEmailField) this.toggleEmailField(false);
			this.form
				.get("account.passwordGroup.password")
				.setValidators([Validators.required]);
		} else {
			this.toggleEmailField(true);
			this.form.get("account.passwordGroup.password").setValidators(null);

			this.form
				.get("account.passwordGroup.password")
				.updateValueAndValidity({ emitEvent: false });
		}
	}

	private togglePasswordFields(enable: boolean): void {
		const passwordControls = [
			this.form.get("account.passwordGroup.password"),
			this.form.get("account.passwordGroup.newPassword"),
			this.form.get("account.passwordGroup.confirmPassword")
		];
		if (enable) {
			passwordControls.forEach((control) => {
				control.enable({ emitEvent: false });
			});
		} else {
			passwordControls.forEach((control) => {
				control.reset("", { emitEvent: false });
				control.setErrors(null, { emitEvent: false });
				control.removeValidators(Validators.required);
				control.disable({ emitEvent: false });
			});
		}
		this.form.get("account.passwordGroup").updateValueAndValidity({
			emitEvent: false
		});
	}

	onLanguageChange(value) {
		this.selectedLanguage = this.languages.find(
			(language: Language) => language._id === value
		);
	}
	storeSelectedLanguage(): void {
		if (this.selectedLanguage) {
			this.selectedLanguageIso = this.selectedLanguage.iso;
			localStorage.setItem("language", this.selectedLanguageIso);
			this.translateService.use(this.selectedLanguageIso);
		}
	}

	private toggleEmailField(enable: boolean): void {
		const prevEmail = this.authenticationService.profileInfo().email;
		const emailControls = [
			this.form.get("account.emailGroup.email"),
			this.form.get("account.emailGroup.password")
		];
		if (enable) {
			emailControls.forEach((control) => {
				control.enable({ emitEvent: false });
			});
		} else {
			emailControls.forEach((control) => {
				control.reset(prevEmail, { emitEvent: false });
				control.setErrors(null, { emitEvent: false });
				control.disable({ emitEvent: false });
			});
		}
		this.form.get("account.emailGroup").updateValueAndValidity({
			emitEvent: false
		});
	}

	private loadProfile(): void {
		this.profileService.get().subscribe((profile: Profile) => {
			this.profile = profile;
			this.updateForm(profile);
			this.tariffType = profile.subscription.type;
			this.tariffLabel = TariffUtil.getLabel(this.tariffType);
			this.isTariffExpired = profile.subscription.expired;
			const selectedLanguage = this.languageService.languages.find(
				(lang) => lang._id === profile.languageId
			);
			localStorage.setItem("language", selectedLanguage.iso);
			if (selectedLanguage) {
				this.languageService.selectedLangId = selectedLanguage._id;
				this.languageService.selectedLocale = selectedLanguage.iso;
			}
		});
	}

	private loadTimezones(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				this.profileService.getTimezones().subscribe(
					(timezones: Timezone[]) => {
						this.timezones = timezones;
						resolve(null);
					},
					(error) => {
						reject();
					}
				);
			}
		);
	}

	private loadLanguages(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				if (
					!this.languageService.languages ||
					!this.languageService.languages.length ||
					this.languageService.languages.length === 0
				) {
					this.profileService.getLanguages().subscribe(
						(languages: LanguagesList) => {
							this.languageService.languages = this.languages =
								languages.list;
							resolve(null);
						},
						(error) => {
							reject();
						}
					);
				} else {
					this.languages = this.languageService.languages;
					resolve(null);
				}
			}
		);
	}

	private updateForm(profile: Profile): void {
		let socialsObj = null;

		this.isOtherGender =
			!this.gendersOptions.some(
				(option) => option.value === profile.gender
			) && profile.gender !== null;

		if (profile.socials) {
			socialsObj = {};
			profile.socials.forEach((link, index) => {
				socialsObj[`link${index + 1}`] = link;
			});
			this.numberOfLinks = profile.socials.length;
		}

		this.form.patchValue({
			account: {
				firstName: profile.firstName,
				lastName: profile.lastName,
				passwordGroup: {
					password: "",
					newPassword: "",
					confirmPassword: ""
				},
				emailGroup: {
					email: profile.email
				},
				phoneNumber: profile.phoneNumber,
				language: profile.languageId,
				timezone: profile.timezone,
				betaTesting: profile.betaTesting
			},
			personal: {
				photo: profile.photo,
				gender: this.isOtherGender ? "other" : profile.gender,
				otherGender: this.isOtherGender ? profile.gender : null,
				dob: profile.dob?.split("T")[0] || null,
				teacherIntroduction: profile.teacherIntroduction,
				socials: socialsObj
			},
			musical: {
				// instruments: profile.instruments,
				// musicGenres: profile.musicGenres,
				languages: profile.spokenLanguages
					? profile.spokenLanguages.map((lang) => lang.language)
					: [],
				teachingExperience: profile.teachingExperience,
				studentPreferences: profile.studentPreferences
					? profile.studentPreferences
					: profile.motivateStudents,
				preferenceComment: profile.preferenceComment
			},
			payout: {
				taxNumber: profile.taxNumber,
				taxAddress: profile.taxAddress,
				vatStatus: profile.vatStatus,
				accountName: profile.accountName,
				iban: profile.iban,
				bic: profile.bic
			}
		});
	}

	public toggleShowPassEmail() {
		this.isShowPassEmail = !this.isShowPassEmail;
	}

	public toggleShowPass() {
		this.isShowPass = !this.isShowPass;
	}

	public toggleShowNewPass() {
		this.isShowNewPass = !this.isShowNewPass;
	}

	public toggleShowConfPass() {
		this.isShowConfPass = !this.isShowConfPass;
	}

	public updatePasswordValidity(): void {
		const passInput = this.accountGroup.get("passwordGroup.newPassword");
		const hasErrors = passInput?.errors;
		this.isNotEmpty =
			!hasErrors || (hasErrors && !passInput.errors.required);
		this.hasEnoughPassLength =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.minlength);
		this.hasNumberOrSpecial =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.pattern);
	}

	async openPortal() {
		const { userId } = await this.authenticationService.profile;
		this.stripeService.openPortalSession(userId);
	}

	hasRequiredError(controlName: string): boolean {
		const control = this.form.get(controlName);
		return (
			control.hasError("required") && (control.dirty || this.isShowErrors)
		);
	}

	hasPatternError(controlName: string): boolean {
		const control = this.form.get(controlName);
		return control.hasError("pattern") && control.dirty;
	}

	private saveMusicGroup() {
		const value = this.form.get("musical").value;

		if (value.languages?.length) {
			value.spokenLanguages = value.languages.map((lang) => ({
				language: lang,
				level: ""
			}));
		}

		value.motivateStudents = value.studentPreferences;

		delete value.studentPreferences;

		delete value.languages;

		this.profileService.save(value).subscribe((profile: Profile) => {
			this.form.get("musical").markAsPristine();
			this.authenticationService.resetProfile();
			this.authenticationService.profile.finally();
			this.showSavedMessage();
		});
	}

	private savePayoutGroup() {
		const value = this.form.get("payout").value;
		this.profileService.save(value).subscribe((profile: Profile) => {
			this.form.get("payout").markAsPristine();
			this.authenticationService.resetProfile();
			this.authenticationService.profile.finally();
			this.showSavedMessage();
		});
	}

	get genderValue() {
		const { gender, otherGender } = this.personalGroup.value;

		return gender === "other" ? otherGender : gender;
	}

	get ageIsoString() {
		const dob = this.personalGroup.value.dob;
		const date = new Date(dob);
		return date.toISOString();
	}

	isDirty(tab) {
		return this.form.get(tab).dirty;
	}

	savePhoto() {
		const value = this.photo.value;
		return this.profileService.savePhoto(value).toPromise();
	}

	private async savePersonalGroup() {
		const { dob, teacherIntroduction, socials } = this.personalGroup.value;

		const socialsArray = Object.values(socials).filter(
			(link: string) => !!link.trim()
		);

		const requestObject = {
			socials: socialsArray,
			gender: this.genderValue,
			dob: this.ageIsoString,
			teacherIntroduction: teacherIntroduction
		};

		if (this.photo.dirty) {
			try {
				await this.savePhoto();
				this.photo.markAsPristine();
			} catch (err) {
				this.photo.setErrors({ is: true });
			}
		}

		this.profileService.save(requestObject).subscribe(
			(profile: Profile) => {
				this.form.get("personal").markAsPristine();
				this.authenticationService.resetProfile();
				this.authenticationService.profile.finally();
				this.showSavedMessage();
			},
			(err) => {
				this.isSavingError = true;
			}
		);
	}

	get personalGroup(): AbstractControl {
		return this.form.get("personal");
	}

	get accountGroup(): AbstractControl {
		return this.form.get("account");
	}

	async saveAccountGroup() {
		const {
			firstName,
			lastName,
			phoneNumber,
			language,
			timezone,
			betaTesting
		} = this.accountGroup.value;

		if (
			this.accountGroup.get("emailGroup").valid &&
			this.accountGroup.get("emailGroup").dirty
		) {
			await this.saveEmail();
		} else if (
			this.accountGroup.get("passwordGroup").valid &&
			this.accountGroup.get("passwordGroup").dirty
		) {
			await this.savePassword();
		}

		this.profileService
			.save({
				firstName,
				lastName,
				phoneNumber,
				language,
				timezone,
				betaTesting
			})
			.subscribe(
				(profile: Profile) => {
					this.form.get("account").markAsPristine();
					this.authenticationService.resetProfile();
					this.authenticationService.profile.finally();
					this.showSavedMessage();
					this.storeSelectedLanguage();
				},
				(err) => {
					this.isSavingError = true;
				}
			);
	}

	showSavedMessage() {
		this.isSavedMessage = true;
		setTimeout(() => {
			this.isSavedMessage = false;
		}, 3000);
	}

	get photo(): AbstractControl {
		return this.form.get("personal.photo");
	}

	async saveTab(tab) {
		this.isShowErrors = false;
		if (tab === "personal") {
			await this.savePersonalGroup();
		} else if (tab === "account") {
			await this.saveAccountGroup();
		} else if (tab === "musical") {
			this.saveMusicGroup();
		} else if (tab === "payout") {
			this.savePayoutGroup();
		}
		this.isShowErrors = true;
	}

	private handleEmailError(err) {
		const description = !!err.error ? err.error.description : null;
		const status = !!err.error ? err.error.status : null;
		const message = !!err.error ? err.error.message : null;
		let errorField = "";
		errorField =
			!errorField && description?.includes("Email has wrong format!")
				? "email"
				: errorField;
		errorField =
			(!errorField && description.includes("Wrong password!")) ||
			description === "Cannot update user profile"
				? "password"
				: errorField;
		if (!!errorField) {
			this.accountGroup
				.get("emailGroup." + errorField)
				.setErrors({ is: true });
		} else {
			errorField =
				description.includes("Data consistency problem") &&
				status === 409 &&
				message === "Conflict"
					? "email"
					: errorField;
			if (!!errorField) {
				this.accountGroup
					.get("emailGroup.email")
					.setErrors({ emailExists: true });
			}
		}
	}

	private handlePasswordError(err) {
		this.accountGroup.get("passwordGroup.password").setErrors({ is: true });
	}

	private async savePassword() {
		try {
			const { password, newPassword } = this.form.get(
				"account.passwordGroup"
			).value;
			const profile = await this.profileService
				.save({ password: password, new_password: newPassword })
				.toPromise();
			this.form.get("account.passwordGroup").markAsPristine();
			this.passwordUpdated = true;
		} catch (err) {
			this.handlePasswordError(err);
		}
	}

	private async saveEmail() {
		try {
			const value = this.form.get("account.emailGroup").value;
			await this.profileService.save(value).toPromise();
			this.form.get("account.emailGroup").markAsPristine();
		} catch (err) {
			this.handleEmailError(err);
		}
	}

	// private loadInstrumentsAndGenres() {
	// 	this.genresOptions = GENRES.map((genre) => ({
	// 		value: genre.value,
	// 		title: this.translateService.instant(genre.title)
	// 	})).sort((a, b) => a.title.localeCompare(b.title));

	// 	this.instrumentsOptions = INSTRUMENTS.map((inst) => ({
	// 		value: inst.value,
	// 		title: this.translateService.instant(inst.title)
	// 	})).sort((a, b) => a.title.localeCompare(b.title));
	// }
	openTypeformPopUp() {
		console.log("Typeform PopUp");
		const { email, firstName, lastName } = this.profile;
		createPopup("bHT9DGta", {
			size: 90,
			hidden: {
				email: email,
				firstname: firstName,
				lastname: lastName
			}
		}).open();
	}

	addLanguage() {}
}
