<h1>
	{{
		(isMultipleNotes
			? "student-notes.title-multiple"
			: "student-notes.title"
		) | translate
	}}
</h1>

<ul *ngIf="isMultipleNotes" class="flex flex-col items-center">
	<li *ngFor="let note of notes" class="py-4 mb-4 !bg-white text-container">
		<div class="flex justify-between">
			<div class="px-6 text-lg font-bold">
				{{ note.title }}
			</div>

			<button
				*ngIf="!isEditorOpen(note)"
				(click)="openEditor(note)"
				class="!rounded-full blue-ui-button px-4 mr-6 font-semibold"
			>
				{{ "buttons.show" | translate }}
			</button>

			<button
				*ngIf="isEditorOpen(note)"
				(click)="closeEditor()"
				class="flex items-center mr-6"
			>
				<img src="../../assets/icons/common/less.svg" alt="less" />
			</button>
		</div>

		<div *ngIf="isEditorOpen(note)" class="mt-3">
			<hr />
			<app-notes-editor [note]="note" [isStudent]="true">
			</app-notes-editor>
		</div>
	</li>
</ul>

<div *ngIf="isSingleNote" class="flex flex-col items-center">
	<div class="px-6 py-4 !bg-white text-container">
		<div class="flex items-center justify-between w-full">
			<h2 class="mt-1 !text-lg !font-semibold">
				{{ "notes-editor.tasks" | translate }}
			</h2>
			<!-- <div class="text-xs font-semibold">{{'student-notes.last-edit' | translate}} {{modified}}</div> -->
		</div>
		<app-quill
			[content]="notes[0].tasksJson"
			[isStudent]="true"
		></app-quill>
	</div>
	<div class="px-6 py-4 mt-4 !bg-white text-container">
		<div class="flex items-center justify-between w-full">
			<h2 class="mt-1 !text-lg !font-semibold">
				{{ "notes-editor.general-notes" | translate }}
			</h2>
			<!-- <div class="text-xs font-semibold">{{'student-notes.last-edit' | translate}} {{modified}}</div> -->
		</div>
		<app-quill
			[content]="notes[0].notesJson"
			[isStudent]="true"
		></app-quill>
	</div>
</div>

<div *ngIf="isNoNotes" class="flex flex-col items-center">
	{{ "student-notes.empty-list-message" | translate }}
</div>
