<div class="video-controls z-14 max-sm:!hidden" *ngIf="!isMobile"
    [ngClass]="[theme.iconColor, theme.activeColor === 'dark' ? 'dark-active' : '']">
    <div *ngIf="!isHiddenSetting" class="control-section">
        <button [attr.aria-label]="'aria-label.open-settings' | translate" mat-mini-fab
            (click)="doToggleSettings($event)" class="control-desktop">
            <mat-icon svgIcon="settings"></mat-icon>
        </button>
        <p class="small-font">
            {{ (!isTablet ? "call-controls.settings" : "call-controls.settings-tablet") | translate }}
        </p>
    </div>
    <div class="control-section" [matTooltipClass]="{ 'tool-tip-custom': !hasCamera }"
        [matTooltip]="'video-settings.tooltip.permission.video' | translate" [matTooltipDisabled]="hasCamera"
        [matTooltipPosition]="'above'">
        <div class="wrapper" (mouseenter)="showVideoSettings()" (mouseleave)="hideVideoSettings()">
            <app-video-menu *ngIf="showVideoSubmenu" (openVideoSettings)="doToggleVideoSettings()"
                (toggleCamera)="doToggleCamera()" [isCamera]="isCamera"></app-video-menu>
            <button [attr.aria-label]="
					(isCamera ? 'aria-label.deactivate-camera' : 'aria-label.activate-camera') | translate
				" mat-mini-fab [disabled]="!hasCamera && !isScreenhare && !isBot" (click)="doToggleCamera()"
                [class.red-active]="!isCamera && !isBot" [hidden]="!(permissions.camera | async)"
                class="control-desktop">
                <mat-icon svgIcon="cam{{ !isCamera && !isBot ? '-off' : '' }}"></mat-icon>
            </button>
            <p class="small-font">{{ "call-controls.cam" | translate }}</p>
        </div>
    </div>
    <div class="control-section">
        <div class="wrapper" (mouseenter)="showAudioSettings()" (mouseleave)="hideAudioSettings()">
            <app-audio-menu *ngIf="showAudioSubmenu" (openAudioSettings)="doToggleAudioSettings()"
                (toggleMicro)="doToggleMicro()" [isMicro]="isMicro">
            </app-audio-menu>
            <button [attr.aria-label]="
					(isMicro ? 'aria-label.deactivate-microphone' : 'aria-label.activate-microphone') | translate
				" mat-mini-fab (click)="doToggleMicro()" [class.red-active]="!isMicro && !isBot"
                [hidden]="!(permissions.microphone | async)" class="control-desktop">
                <mat-icon svgIcon="mic{{ !isMicro && !isBot ? '-off' : '' }}"></mat-icon>
            </button>
            <p class="small-font">{{ "call-controls.mic" | translate }}</p>
        </div>
    </div>
    <div class="spacer first"></div>
    <div *ngIf="!isHiddenInterrupt" class="control-section">
        <button [attr.aria-label]="(isPiano ? 'aria-label.close-piano' : 'aria-label.open-piano') | translate"
            mat-mini-fab [ngClass]="[
				'control-desktop',
				isPiano ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" (click)="doTogglePiano(!isPiano)">
            <mat-icon svgIcon="piano"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.piano" | translate }}</p>
    </div>
    <!-- <div class="control-section">
        <button [attr.aria-label]="" mat-mini-fab [ngClass]="['control-desktop', (isHandOn ?'selected':'')]"
            [hidden]="!(permissions.risehand | async) || isHiddenInterrupt" (click)="doToggleHandOn(!isHandOn)">
            <mat-icon svgIcon="sound"></mat-icon>
        </button>
        <p *ngIf="!isHiddenInterrupt" class="small-font">{{ 'call-controls.sound' | translate }}</p>
    </div> -->
    <div *ngIf="!isHiddenMetronome" class="control-section">
        <button
            [attr.aria-label]="(isMetronome ? 'aria-label.close-metronome' : 'aria-label.open-metronome') | translate"
            mat-mini-fab [ngClass]="[
				'control-desktop',
				isMetronome ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" (click)="doToggleMetronome(!isMetronome)">
            <mat-icon svgIcon="metronome"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.metronome" | translate }}</p>
    </div>
    <div class="control-section">
        <button [attr.aria-label]="" mat-mini-fab
            [ngClass]="['control-desktop', (isTuner ?'toggle-active':''), (theme.activeColor === 'dark' ? 'toggle-dark' : '')]"
            [hidden]="isHiddenTuner" (click)="doToggleTuner(!isTuner)">
            <mat-icon svgIcon="tuner"></mat-icon>
        </button>
        <p *ngIf="!isHiddenTuner" class="small-font">{{ 'call-controls.tuner' | translate }}</p>
    </div>
    <div *ngIf="!isHiddenEmoji">
        <button [attr.aria-label]="(isEmoji ? 'aria-label.close-emoji' : 'aria-label.open-emoji') | translate"
            mat-mini-fab [ngClass]="[
				'control-desktop',
				isEmoji ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" (click)="doToggleEmoji(!isEmoji)">
            <mat-icon svgIcon="emoji" class="mb-px"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.reaction" | translate }}</p>
    </div>

    <div class="control-section" *ngIf="canScreenshare()">
        <div *ngIf="isToggleShare" class="share-list-block" [ngStyle]="{
				transform: isScreenhare ? 'translate(-22%, -128%)' : 'translate(-14%, -128%);'
			}">
            <ul class="box-border">
                <li *ngIf="!isYouTube" [attr.aria-label]="'aria-label.open-share-youtube' | translate"
                    class="text-base text-black cursor-pointer md:text-xs hover:bg-gray-100"
                    (click)="onSelectShareType('YouTube')">
                    YouTube
                </li>
                <li *ngIf="isYouTube" [attr.aria-label]="'aria-label.stop-sharing-youtube' | translate"
                    class="text-base text-black cursor-pointer md:text-xs hover:bg-gray-100"
                    (click)="onSelectShareType('YouTube')">
                    {{ "youtube.stop-youtube" | translate }}
                </li>
                <li *ngIf="isScreenhare" [attr.aria-label]="'aria-label.stop-share-window-tab-screen' | translate"
                    class="text-base text-black cursor-pointer md:text-xs hover:bg-gray-100"
                    (click)="onSelectShareType('screenShare')">
                    {{ "call-controls.stop-share-screen" | translate }}
                </li>
                <li *ngIf="!isScreenhare" [attr.aria-label]="'aria-label.open-share-window-tab-screen' | translate"
                    class="text-base text-black cursor-pointer md:text-xs hover:bg-gray-100"
                    (click)="onSelectShareType('screenShare')">
                    {{ "call-controls.share-screen" | translate }}
                </li>
            </ul>
        </div>
        <button [attr.aria-label]="
				(isToggleShare ? 'aria-label.close-share-menu' : 'aria-label.open-share-menu') | translate
			" mat-mini-fab [ngClass]="[
				'control-desktop',
				isScreenhare ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" (click)="doToggleShare()">
            <!--      (click)="doToggleScreenShare(!isScreenhare)">-->
            <mat-icon svgIcon="share{{ isScreenhare ? '-off' : '' }}"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.share" | translate }}</p>
    </div>
    <div *ngIf="!isHiddenSplitView" class="hide-on-mobile control-section">
        <button [attr.aria-label]="
				(isSplitView ? 'aria-label.activate-speaker-view' : 'aria-label.activate-gallery-view') | translate
			" mat-mini-fab class="control-desktop" (click)="doToggleSplitView(!isSplitView)">
            <mat-icon svgIcon="{{ isSplitView ? 'speakerview' : 'galeryview' }}"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.view" | translate }}</p>
    </div>
    <div *ngIf="!isHiddenChat" class="control-section">
        <button [attr.aria-label]="(displayChat ? 'aria-label.close-chat' : 'aria-label.open-chat') | translate"
            mat-mini-fab [ngClass]="[
				'control-desktop',
				displayChat ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" (click)="doToggleChat()">
            <div class="messages-icon-container">
                <div *ngIf="hasUnreadMessages && !displayChat" class="unread-messages"></div>
                <mat-icon svgIcon="chat"></mat-icon>
            </div>
        </button>
        <p class="small-font">{{ "call-controls.chat" | translate }}</p>
    </div>
    <!-- add aria-label -->
    <div *ngIf="!isHiddenNotes" class="control-section">
        <button [ngClass]="[
				'control-desktop',
				displayNotes ? 'toggle-active' : '',
				theme.activeColor === 'dark' ? 'toggle-dark' : ''
			]" mat-mini-fab class="control-desktop" (click)="doToggleNotes()">
            <mat-icon class="ml-[3px]" svgIcon="notes"></mat-icon>
        </button>
        <p class="small-font">{{ "menu.notes" | translate }}</p>
    </div>
    <!-- <div class="hide-on-mobile control-section" [hidden]="isHiddenInvitation">
    <button [attr.aria-label]="" mat-mini-fab class="control-desktop" (click)="doToggleInvitation()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <p class="small-font">{{ 'call-controls.add' | translate }}</p>
  </div> -->
    <div *ngIf="(enableRecordings || hasTestRoom) && (isBot || isTeacher || subrole === 3)" class="control-section">
        <button [attr.aria-label]="
				(startRecord && (isBotOrProTeacher || subrole === 3) ? 'deactivate-recording' : 'activate-recording')
					| translate
			" mat-mini-fab class="control-desktop" (click)="doToggleRecord()">
            <mat-icon class="small-size"
                svgIcon="{{ startRecord && (isBotOrProTeacher || subrole === 3) ? 'record-on' : 'record' }}"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.record" | translate }}</p>
    </div>
    <div class="spacer"></div>
    <!-- <button [attr.aria-label]="" class="leave-btn default-ui-button orange-ui-button" (click)="doFinishCall()" [hidden]="isHiddenLeaveButton">
    {{ 'call-controls.leave' | translate }}
  </button> -->

    @if (isStudentTrial) {
    <div class="control-section flex next-steps-container" (click)="doFinishCall()">
        <div class="px-2 next-steps">
            <p class="small-font text-sm !pt-0" [innerHTML]="'call-controls.next-steps' | translate | safeHtml"></p>
        </div>
        <div class="end-btn" [class.next]="isStudentTrial">
            <button [attr.aria-label]="'aria-label.leave-call' | translate" mat-mini-fab>
                <mat-icon class="leave" svgIcon="next"></mat-icon>
            </button>
            <p class="small-font"></p>
        </div>
    </div>

    <div class="control-section next-steps-tablet" (click)="doFinishCall()">
        <div class="end-btn" [class.next]="isStudentTrial">
            <button [attr.aria-label]="'aria-label.leave-call' | translate" mat-mini-fab>
                <mat-icon class="leave" svgIcon="next"></mat-icon>
            </button>
            <p class="small-font">{{ "call-controls.next-steps-short-tablet" | translate }}</p>
        </div>
    </div>
    } @else {
    <div class="end-btn" [class.next]="isStudentTrial">
        <button [attr.aria-label]="'aria-label.leave-call' | translate" mat-mini-fab (click)="doFinishCall()">
            <mat-icon class="leave" svgIcon="leave"></mat-icon>
        </button>
        <p class="small-font">{{ "call-controls.leave" | translate }}</p>
    </div>
    }
</div>

<!-- small screens -->
<div class="w-full flex flex-col justify-end h-full !bg-transparent" [ngClass]="{ 'sm:hidden': !isMobile }">
    <div class="flex flex-row justify-around w-full px-5 text-center controls-mobile grow-0 h-28 max-xs:px-0"
        [ngClass]="[theme.iconColor, theme.activeColor === 'dark' ? 'dark-active' : '', isMobile ? 'pt-[8px]' : '']">
        <div>
            @if (isStudentTrial) {
            <button [attr.aria-label]="'aria-label.leave-call' | translate"
                class="!bg-indigo hover:!bg-indigo-hover !h-14 !w-14 !shadow-none" mat-mini-fab
                (click)="doFinishCall()">
                <mat-icon svgIcon="next" class="leave"></mat-icon>
            </button>
            <p *ngIf="!isMobile" class="mobile-text !text-center pt-2 max-xs:text-sm !leading-4"
                [innerHTML]="'call-controls.next-steps-short' | translate | safeHtml"></p>
            } @else {
            <button *ngIf="!isStudentTrial" [attr.aria-label]="'aria-label.leave-call' | translate"
                class="!bg-btn-red hover:!bg-btn-red-hover !h-14 !w-14 !shadow-none" mat-mini-fab
                (click)="doFinishCall()">
                <mat-icon svgIcon="leave" class="leave"></mat-icon>
            </button>

            <p *ngIf="!isMobile" class="mobile-text !text-center pt-2 max-xs:text-sm">
                {{ "call-controls.leave" | translate }}
            </p>
            }
        </div>
        <div>
            <button [attr.aria-label]="'aria-label.toggle-camera' | translate" mat-mini-fab (click)="doToggleCamera()"
                [class.red-active]="!isCamera" [hidden]="!(permissions.camera | async)"
                class="control-mobile !h-14 !w-14 !shadow-none">
                <mat-icon svgIcon="cam{{ !isCamera ? '-off' : '' }}"></mat-icon>
            </button>
            <p *ngIf="!isMobile" class="pt-2 text-center mobile-text max-xs:text-sm">
                {{ "call-controls.cam" | translate }}
            </p>
        </div>
        <div>
            <button [attr.aria-label]="'aria-label.toggle-microphone' | translate" mat-mini-fab
                (click)="doToggleMicro()" [class.red-active]="!isMicro" [hidden]="!(permissions.microphone | async)"
                class="control-mobile !h-14 !w-14 !shadow-none">
                <mat-icon svgIcon="mic{{ !isMicro ? '-off' : '' }}"></mat-icon>
            </button>
            <p *ngIf="!isMobile" class="pt-2 text-center mobile-text max-xs:text-sm">
                {{ "call-controls.mic" | translate }}
            </p>
        </div>
        <div *ngIf="isMobile || isTablet">
            <button [attr.aria-label]="'aria-label.switch-camera' | translate" mat-mini-fab
                (click)="doToggleSwitchCam()" class="control-mobile !h-14 !w-14 !shadow-none">
                <mat-icon svgIcon="switch-cam"></mat-icon>
            </button>
            <p *ngIf="!isMobile" class="pt-2 text-center mobile-text max-xs:text-sm">
                {{ "call-controls.switch-cam" | translate }}
            </p>
        </div>
        <div>
            <button [attr.aria-label]="'aria-label.open-second-menu-tab' | translate" mat-mini-fab
                (click)="setIsMore(true)" [hidden]="!(permissions.microphone | async)"
                class="control-mobile mobile-text !h-14 !w-14 !shadow-none">
                <mat-icon class="scale-150">more_horiz</mat-icon>
            </button>
            <p *ngIf="!isMobile" class="pt-2 text-center mobile-text max-xs:text-sm">
                {{ "call-controls.more" | translate }}
            </p>
        </div>
    </div>

    <!-- mobile 'more' tab -->

    <div [@MenuSlide] *ngIf="isMore" class="w-full absolute flex flex-col justify-end !bg-transparent px-1 z-20"
        [ngClass]="{ 'sm:hidden': !isMobile }">
        <!-- emoji picker -->

        <div class="w-full h-14 px-4 max-xs:px-0 rounded-md !bg-btn-grey">
            <app-emoji-picker (emojiSelected)="onEmojiSelected($event)" [isMobile]="true"></app-emoji-picker>
        </div>

        <!-- menu -->

        <div
            class="w-full mt-1 rounded-t-md !bg-btn-grey grid grid-cols-4 grid-rows-2 text-center max-xs:px-0 px-4 pt-3 h-[196px]">
            <!-- row 1 -->
            <div *ngIf="(enableRecordings || hasTestRoom) && (isBot || isTeacher || subrole === 3)"
                class="!bg-transparent col-span-1 col-start-3">
                <button [attr.aria-label]="'aria-label.toggle-recording' | translate" mat-mini-fab
                    class="!bg-transparent !h-14 !w-14 !shadow-none" (click)="doToggleRecord()">
                    <mat-icon class="small-size"
                        svgIcon="{{ startRecord && (isBotOrProTeacher || subrole === 3) ? 'record-on' : 'record' }}"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "call-controls.record" | translate }}
                </p>
            </div>
            <div *ngIf="!isHiddenSplitView" class="!bg-transparent col-span-1 col-start-4">
                <button [attr.aria-label]="'aria-label.change-view' | translate" [disabled]="isMobLandscape"
                    mat-mini-fab class="!bg-transparent !h-14 !w-14 !shadow-none"
                    (click)="doToggleSplitView(!isSplitView)">
                    <mat-icon class="more-icon" svgIcon="{{ isSplitView ? 'speakerview' : 'galeryview' }}"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "call-controls.view" | translate }}
                </p>
            </div>

            <!-- row 2 -->

            <div *ngIf="!isHiddenSetting" class="!bg-transparent col-span-1 row-start-2 col-start-1">
                <button [attr.aria-label]="'aria-label.open-settings' | translate" mat-mini-fab
                    (click)="doToggleSettings($event); setIsMore(false)"
                    class="!bg-transparent !h-14 !w-14 !shadow-none">
                    <mat-icon class="more-icon" svgIcon="settings"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "video-settings.settings" | translate }}
                </p>
            </div>
            <div *ngIf="!isHiddenMetronome" class="!bg-transparent col-span-1">
                <button [attr.aria-label]="'aria-label.toggle-metronome' | translate" mat-mini-fab
                    [ngClass]="['!bg-transparent !h-14 !w-14 !shadow-none', isMetronome ? '!bg-sirius-lilac' : '']"
                    (click)="doToggleMetronome(!isMetronome); setIsMore(false)">
                    <mat-icon svgIcon="metronome" class="scale-125 ml-[0.5px] more-icon"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "call-controls.metronome" | translate }}
                </p>
            </div>
            <div *ngIf="!isHiddenChat" class="!bg-transparent col-span-1">
                <button [attr.aria-label]="'aria-label.toggle-chat' | translate" mat-mini-fab
                    [ngClass]="['!bg-transparent !h-14 !w-14 !shadow-none', displayChat ? '!bg-sirius-lilac' : '']"
                    (click)="doToggleChat(); setIsMore(false)">
                    <div *ngIf="hasUnreadMessages && !displayChat"
                        class="!border-white border !bg-red-600 w-[12px] h-[12px] rounded-full absolute"></div>
                    <mat-icon class="more-icon" svgIcon="chat"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "call-controls.chat" | translate }}
                </p>
            </div>
            <div class="!bg-transparent col-span-1">
                <button [attr.aria-label]="'aria-label.close-second-menu-tab' | translate" mat-mini-fab
                    class="!bg-transparent !h-14 !w-14 !shadow-none" (click)="setIsMore(false)">
                    <mat-icon class="more-icon text-white" svgIcon="close" class="scale-75"></mat-icon>
                </button>
                <p class="pt-2 text-center text-white max-xs:text-sm">
                    {{ "call-controls.close" | translate }}
                </p>
            </div>
        </div>
    </div>
</div>