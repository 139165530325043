{
  "name": "client",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "node config.js && ng serve --host 0.0.0.0 --disable-host-check --port 4200 --ssl true",
    "start:http": "node config.js && ng serve --disable-host-check",
    "build": "node config.js && ng build --configuration=local",
    "build:local": "node config.js && ng build --configuration=local",
    "build:master": "node config.js && ng build --configuration=master",
    "build:staging": "node config.js && ng build --configuration=staging",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "startwithnvm": "source $HOME/.nvm/nvm.sh; nvm use; node config.js && ng serve --live-reload true --host 0.0.0.0 --disable-host-check --port 4200 --ssl true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/elements": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/material": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@angular/youtube-player": "^18.1.0",
    "@chordbook/tuner": "^0.0.3",
    "@fortawesome/fontawesome-free": "^5.12.1",
    "@fullcalendar/angular": "^6.1.14",
    "@fullcalendar/core": "^6.1.14",
    "@fullcalendar/interaction": "^6.1.14",
    "@fullcalendar/timegrid": "^6.1.14",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^6.0.0",
    "@peermetrics/webrtc-stats": "^5.6.0",
    "@sentry/angular": "^8.9.2",
    "@testrtc/watchrtc-sdk": "^1.39.1",
    "@typeform/embed": "^5.1.0",
    "@types/webmidi": "^2.0.7",
    "body-parser": "^1.19.0",
    "cors": "^2.8.5",
    "dayjs": "^1.11.9",
    "document-register-element": "^1.7.2",
    "dotenv": "^8.2.0",
    "express": "^4.17.1",
    "fs": "0.0.1-security",
    "http": "0.0.1-security",
    "https": "^1.0.0",
    "intl-tel-input": "^18.2.1",
    "luxon": "^3.4.4",
    "material-symbols": "^0.21.2",
    "ngx-device-detector": "^8.0.0",
    "ngx-material-timepicker": "^13.1.1",
    "open-easyrtc": "git+https://github.com/open-easyrtc/open-easyrtc.git#socket.io-4",
    "path": "^0.12.7",
    "quill": "^2.0.2",
    "rxjs": "^6.4.0",
    "socket.io-client": "^4.7.2",
    "talkjs": "^0.25.0",
    "tone": "^14.7.77",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/language-service": "^18.1.0",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@typescript-eslint/eslint-plugin": "7.10.0",
    "@typescript-eslint/parser": "7.10.0",
    "autoprefixer": "^10.4.13",
    "codelyzer": "^6.0.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "jasmine-core": "~4.5.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.17",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "postcss": "^8.4.21",
    "protractor": "~7.0.0",
    "tailwindcss": "^3.2.4",
    "typescript": "~5.5.3",
    "webpack-bundle-analyzer": "^4.9.0"
  }
}
