<h1 *ngIf="tab === 'account'">{{ "profile.account-settings" | translate }}</h1>
<h1 *ngIf="tab === 'personal'">{{ "profile.your-profile" | translate }}</h1>
<!-- <h1 *ngIf="tab === 'musical'">{{ "profile.musical-profile" | translate }}</h1> -->
<h1 *ngIf="tab === 'payout' && subrole === SubRole.TEACHER_MATCHED">
	{{ "profile.payout-details" | translate }}
</h1>
<h1 *ngIf="tab === 'payout' && subrole === SubRole.TEACHER_SELF">
	{{ "profile.your-membership" | translate }}
</h1>
<h1 *ngIf="tab === 'payout' && subrole === SubRole.STUDENT_MATCHED">
	{{ "profile.your-membership" | translate }}
</h1>
<h1 *ngIf="tab === 'notifications'">
	{{ "notifications-settings.title" | translate }}
</h1>

<div class="flex m-auto flex-col profile-container">
	<ul class="tab-menu rounded-t-border-radius">
		<li>
			<a [routerLink]="['/platform/profile']" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: true }">{{
				"profile.account-tab" | translate
			}}</a>
		</li>
		<li *accessControl="Resources.PROFILE_PERSONAL">
			<a [routerLink]="['/platform/profile/personal']" [routerLinkActive]="['is-active']">{{ "profile.profile-tab" | translate }}</a>
		</li>
		<!-- <li>
			<a [routerLink]="['/platform/profile/musical']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden">{{ "profile.musical-tab" | translate }}</span>
				<span class="md:hidden">{{ "profile.musical-tab-mobile" | translate }}</span>
			</a>
		</li> -->

		<li *accessControl="Resources.PROFILE_SUBSCRIPTION">
			<a [routerLink]="['/platform/profile/payout']" [routerLinkActive]="['is-active']">
				<span *ngIf="subrole === SubRole.TEACHER_SELF">
					{{ "profile.subscription-tab" | translate }}
				</span>
			</a>
		</li>
		<li *accessControl="Resources.PROFILE_MEMBERSHIP">
			<a [routerLink]="['/platform/profile/payout']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden" [innerHTML]="'profile.membership-tab' | translate"></span>
				<span class="md:hidden" [innerHTML]="'profile.membership-tab-mobile' | translate"></span>
			</a>
		</li>
		<li *accessControl="Resources.PROFILE_PAYOUT">
			<a [routerLink]="['/platform/profile/payout']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden" [innerHTML]="'profile.payout-tab' | translate"></span>
				<span class="md:hidden" [innerHTML]="'profile.payout-tab-mobile' | translate"></span>
			</a>
		</li>
		<li *accessControl="Resources.PROFILE_NOTIFICATIONS">
			<a
				[routerLink]="['/platform/profile/notifications']"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: true }"
				>{{ "profile.notifications-tab" | translate }}</a
			>
		</li>
	</ul>

	<div class="dropdown-menu">
		<mat-select [value]="tab" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border">
			<mat-option [routerLink]="['/platform/profile']" value="account">
				{{ "profile.account-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_PERSONAL" [routerLink]="['/platform/profile/personal']" value="personal">
				{{ "profile.profile-tab" | translate }}
			</mat-option>
			<!-- <mat-option [routerLink]="['/platform/profile/musical']" value="musical">
				{{ "profile.musical-tab" | translate }}
			</mat-option> -->

			<mat-option *accessControl="Resources.PROFILE_PAYOUT" [routerLink]="['/platform/profile/payout']" value="payout">
				{{ "profile.payout-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_SUBSCRIPTION" [routerLink]="['/platform/profile/payout']" value="payout">
				{{ "profile.subscription-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_MEMBERSHIP" [routerLink]="['/platform/profile/payout']" value="payout">
				{{ "profile.membership-tab" | translate }}
			</mat-option>
			<mat-option
				*accessControl="Resources.PROFILE_NOTIFICATIONS"
				[routerLink]="['/platform/profile/notifications']"
				value="notifications"
			>
				{{ "profile.notifications-tab" | translate }}
			</mat-option>
		</mat-select>
	</div>
	<form *ngIf="tab !== 'notifications'" class="profile-table" [formGroup]="form" autocomplete="off">
		<!-- for stopping autofill -->
		<input autocomplete="false" name="hidden" type="text" style="display: none" />
		<input autocomplete="false" name="hidden2" type="password" style="display: none" />
		<div *ngIf="tab === 'account'" formGroupName="account">
			<!-- <h1>{{ "profile.account-settings" | translate }}</h1> -->
			<div class="form-row">
				<div class="form-label">
					{{ "profile.first-name-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="firstName" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('account.firstName')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.last-name-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="lastName" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('account.lastName')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div formGroupName="emailGroup">
				<div class="form-row">
					<div class="form-label">
						{{ "profile.email-label" | translate }}
					</div>
					<div class="form-input">
						<input formControlName="email" type="text" />
						<div class="error-message" *ngIf="hasRequiredError('account.emailGroup.email')">
							{{ "forms.required" | translate }}
						</div>
						<div class="error-message" *ngIf="hasPatternError('account.emailGroup.email')">
							{{ "forms.invalid-format" | translate }}
						</div>
						<div class="error-message" *ngIf="accountGroup.get('emailGroup.email').hasError('emailExists')">
							{{ "sign-up.email-exists" | translate }}
						</div>

						<div *ngIf="displayPassEmail" class="mt-2">
							<div>
								{{ "profile.password-label" | translate }}
							</div>
							<div class="password-field-outer">
								<input
									autocomplete="new-password"
									[type]="isShowPassEmail ? 'text' : 'password'"
									formControlName="password"
									[placeholder]="'profile.password-placeholder' | translate"
								/>
								<div
									class="toggle-password-visibility"
									[ngClass]="[isShowPassEmail ? 'show' : 'hide']"
									(click)="toggleShowPassEmail()"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div formGroupName="passwordGroup">
				<div class="form-row">
					<div class="form-label">
						{{ "profile.login-password-label" | translate }}
					</div>
					<div class="form-input">
						<div class="password-field-outer mb-2">
							<input
								autocomplete="off"
								[type]="isShowPass || !passwordHasBeenTouched ? 'text' : 'password'"
								formControlName="password"
								[placeholder]="'profile.old-password-placeholder' | translate"
								(focus)="handlePasswordFocus()"
							/>
							<div
								class="toggle-password-visibility"
								[ngClass]="[isShowPass ? 'show' : 'hide']"
								(click)="toggleShowPass()"
							></div>
						</div>
						<div class="w-full">
							<div class="field-new-password">
								<div class="password-field-outer">
									<input
										autocomplete="off"
										[type]="isShowNewPass || !passwordHasBeenTouched ? 'text' : 'password'"
										formControlName="newPassword"
										[placeholder]="'profile.new-password-placeholder' | translate"
									/>
									<div
										class="toggle-password-visibility"
										[ngClass]="[isShowNewPass ? 'show' : 'hide']"
										(click)="toggleShowNewPass()"
									></div>
								</div>
								<div
									class="error-message"
									*ngIf="
										form.get('account.passwordGroup.newPassword').dirty &&
										form.get('account.passwordGroup.newPassword').invalid
									"
								>
									{{ "profile.new-password-error" | translate }}
								</div>
								<div class="mt-2">
									<app-password-requirements
										class="password-requirements"
										[hasEnoughPassLength]="hasEnoughPassLength"
										[hasNumberOrSpecial]="hasNumberOrSpecial"
										[isNotEmpty]="isNotEmpty"
									>
									</app-password-requirements>
								</div>
							</div>

							<div class="password-field-outer">
								<input
									autocomplete="off"
									[type]="isShowConfPass || !passwordHasBeenTouched ? 'text' : 'password'"
									formControlName="confirmPassword"
									[placeholder]="'profile.confirm-password-placeholder' | translate"
								/>
								<div
									class="toggle-password-visibility"
									[ngClass]="[isShowConfPass ? 'show' : 'hide']"
									(click)="toggleShowConfPass()"
								></div>
							</div>
							<div
								class="error-message"
								*ngIf="
									form.get('account.passwordGroup.newPassword').touched &&
									form.get('account.passwordGroup').errors?.passwordsMismatch
								"
							>
								{{ "profile.passwords-mismatch-error" | translate }}
							</div>
						</div>
						<div class="error-message" *ngIf="form.get('account.passwordGroup.password').hasError('is')">
							{{ "lobby-popup-desktop.password-incorrect" | translate }}
						</div>
					</div>
				</div>
			</div>
			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.phone.title" | translate }}
				</div>
				<div class="form-input">
					<app-intl-tel-input formControlName="phoneNumber"></app-intl-tel-input>
					<div class="error-message" *ngIf="hasRequiredError('account.phoneNumber')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.language-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="language"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
					>
						<mat-option *ngFor="let language of languages" [value]="language._id">
							{{ language.name }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.timezone-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="timezone"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
					>
						<mat-option *ngFor="let timezone of timezones" [value]="timezone">
							{{ timezone }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<!-- <div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "profile.beta-testing-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="betaTesting"
						class="s-mat-select app-select border"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
					>
						<mat-option
							*ngFor="let option of betaTestingOptions"
							[value]="option.value"
						>
							{{ option.title | translate }}
						</mat-option>
					</mat-select>
				</div>
			</div> -->
		</div>

		<div *ngIf="tab === 'personal'" formGroupName="personal">
			<!-- <h1>{{ "profile.your-profile" | translate }}</h1> -->
			<div class="photo-row">
				<div class="form-label">
					{{ "profile.photo-label" | translate }}
				</div>
				<div class="form-input pt-[6px]">
					<app-photo-upload formControlName="photo"></app-photo-upload>
					<div *ngIf="photo.hasError('is')" class="error-message">
						{{ "forms.photo-error" | translate }}
					</div>
				</div>
			</div>
			<!-- <div class="form-row">
				<div class="form-label">
					{{ "profile.age-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="dob" class="dob" type="date" [min]="minDate" [max]="maxDate" />
					<div *ngIf="hasRequiredError('personal.dob')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div> -->
			<div class="form-row">
				<div class="form-label">
					{{ "profile.gender-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="gender"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						[placeholder]="'teacher-onboarding.gender.placeholder' | translate"
					>
						<mat-option *ngFor="let g of gendersOptions" [value]="g.value">
							{{ g.title | translate }}
						</mat-option>
					</mat-select>
					<input
						*ngIf="isOtherGender"
						class="mt-2"
						formControlName="otherGender"
						[placeholder]="'teacher-onboarding.gender.placeholder2' | translate"
						type="text"
					/>
					<div *ngIf="hasRequiredError('personal.gender')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{
						(subrole === SubRole.STUDENT_MATCHED ? "profile.about-you-label-student" : "profile.about-you-label-teacher")
							| translate
					}}
				</div>
				<div class="form-input">
					<textarea
						formControlName="teacherIntroduction"
						class="w-full app-textarea"
						rows="3"
						[placeholder]="
							(subrole === SubRole.STUDENT_MATCHED
								? 'profile.description-placeholder-student'
								: 'profile.description-placeholder-teacher'
							) | translate
						"
					></textarea>
					<div *ngIf="hasRequiredError('personal.teacherIntroduction')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row" formGroupName="socials">
				<div class="form-label">
					<div>{{ "profile.socials-label-1" | translate }} /</div>
					<div>
						{{ "profile.socials-label-2" | translate }}
					</div>
				</div>
				<div class="form-input">
					<input type="text" [placeholder]="'teacher-onboarding.social-media.placeholder' | translate" formControlName="link1" />
					<input *ngIf="numberOfLinks > 1" type="text" class="mt-2" formControlName="link2" />
					<input *ngIf="numberOfLinks > 2" type="text" class="mt-2" formControlName="link3" />
					<input *ngIf="numberOfLinks > 3" type="text" class="mt-2" formControlName="link4" />
					<input *ngIf="numberOfLinks > 4" type="text" class="mt-2" formControlName="link5" />
					<button *ngIf="numberOfLinks < 5" class="s-mat-button s-mat-primary add-link-btn mt-[6px]" (click)="addLink()">
						<svg
							stroke="currentColor"
							fill="none"
							stroke-width="2"
							viewBox="0 0 24 24"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="inline-flex h-4 w-4"
							height="1em"
							width="1em"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line x1="12" y1="5" x2="12" y2="19"></line>
							<line x1="5" y1="12" x2="19" y2="12"></line>
						</svg>
						<span>{{ "buttons.add-link" | translate }}</span>
					</button>
					<div *ngIf="hasRequiredError('personal.socials.link1')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
		</div>

		<!-- <div *ngIf="tab === 'musical'" formGroupName="musical">
			@if(subrole === SubRole.STUDENT_MATCHED){
			<div class="form-row">
				<div class="form-label pr-2">
					{{
					(subrole === SubRole.STUDENT_MATCHED ? "profile.instruments-label-student" : "profile.instruments-label-teacher")
					| translate
					}}
				</div>
				<div class="form-input">
					<mat-select formControlName="instruments" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" multiple [placeholder]="'teacher-onboarding.instrument.placeholder' | translate">
						<mat-option *ngFor="let i of instrumentsOptions" [value]="i.value">
							{{ i.title }}
						</mat-option>
					</mat-select>
					<div class="error-message" *ngIf="hasRequiredError('musical.instruments')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{
					(subrole === SubRole.STUDENT_MATCHED ? "profile.genres-label-student" : "profile.genres-label-teacher") | translate
					}}
				</div>
				<div class="form-input">
					<mat-select formControlName="musicGenres" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" multiple [placeholder]="'teacher-onboarding.genre.placeholder' | translate">
						<mat-option *ngFor="let i of genresOptions" [value]="i.value">
							{{ i.title }}
						</mat-option>
					</mat-select>
					<div class="error-message" *ngIf="hasRequiredError('musical.musicGenres')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			}

			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.languages.title" | translate }}
				</div>
				<div class="form-input">
					<mat-select formControlName="languages" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" multiple [placeholder]="'teacher-onboarding.languages.placeholder' | translate">
						<mat-option *ngFor="let l of languagesOptions" [value]="l.value">
							{{ l.title | translate }}
						</mat-option>
					</mat-select>
					<div class="error-message" *ngIf="hasRequiredError('musical.languages')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label pr-2">
					{{
					(subrole === SubRole.STUDENT_MATCHED ? "profile.education-label-student" : "profile.education-label-teacher")
					| translate
					}}
				</div>
				<div class="form-input">
					<textarea formControlName="teachingExperience" class="w-full app-textarea" rows="3" [placeholder]="
							(subrole === SubRole.STUDENT_MATCHED
								? 'profile.education-placeholder-student'
								: 'profile.education-placeholder-teacher'
							) | translate
						"></textarea>
					<div class="error-message" *ngIf="hasRequiredError('musical.teachingExperience')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label pr-2">
					{{ "profile.student-preferences-label" | translate }}
				</div>
				<div class="form-input">
					<textarea formControlName="studentPreferences" class="w-full app-textarea" rows="3" [placeholder]="'teacher-onboarding.student.placeholder' | translate"></textarea>
					<div class="error-message" *ngIf="hasRequiredError('musical.studentPreferences')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "profile.best-match-label" | translate }}
				</div>
				<div class="form-input">
					<textarea formControlName="preferenceComment" class="w-full app-textarea" rows="3" [placeholder]="'teacher-onboarding.best-match.placeholder' | translate"></textarea>
					<div class="error-message" *ngIf="hasRequiredError('musical.preferenceComment')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
		</div> -->

		<div *ngIf="tab === 'payout' && subrole === SubRole.TEACHER_MATCHED" formGroupName="payout">
			<!-- <h1 class="text-lg mb-1">
				{{ "profile.payout-details" | translate }}
			</h1> -->
			<div class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.tax-number.title" | translate }}
				</div>
				<div class="form-input">
					<input
						formControlName="taxNumber"
						[placeholder]="'teacher-onboarding.tax-number.placeholder' | translate"
						type="text"
					/>
					<div class="error-message" *ngIf="hasRequiredError('payout.taxNumber')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.tax-residency.title" | translate }}
				</div>
				<div class="form-input">
					<input
						formControlName="taxAddress"
						type="text"
						[placeholder]="'teacher-onboarding.tax-residency.placeholder' | translate"
					/>
					<div class="error-message" *ngIf="hasRequiredError('payout.taxAddress')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.vat-status.title" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="vatStatus"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						[placeholder]="'teacher-onboarding.vat-status.placeholder' | translate"
					>
						<mat-option *ngFor="let v of vatOptions" [value]="v.value">
							{{ v.title | translate }}
						</mat-option>
					</mat-select>
					<div class="error-message" *ngIf="hasRequiredError('payout.vatStatus')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.bank-holder-label" | translate }}
				</div>
				<div class="form-input">
					<input
						formControlName="accountName"
						[placeholder]="'teacher-onboarding.bank-account.placeholder1' | translate"
						type="text"
					/>
					<div class="error-message" *ngIf="hasRequiredError('payout.accountName')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.iban-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="iban" placeholder="IBAN" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('payout.iban')">
						{{ "forms.required" | translate }}
					</div>
					<div class="error-message" *ngIf="hasPatternError('payout.iban')">
						{{ "forms.invalid-format" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.bic-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="bic" placeholder="BIC" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('payout.bic')">
						{{ "forms.required" | translate }}
					</div>
					<div class="error-message" *ngIf="hasPatternError('payout.bic')">
						{{ "forms.invalid-format" | translate }}
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="tab === 'payout' && subrole === SubRole.TEACHER_SELF" formGroupName="payout">
			<h1 class="text-lg mb-1">
				{{ "profile.your-membership" | translate }}
			</h1>
			<div class="form-row !mb-0">
				<div class="form-label">
					{{ "profile.subscription" | translate }}
				</div>
				<div class="form-input">
					<div class="flex mb-6">
						<input type="text" [value]="tariffLabel | translate" disabled />
					</div>
					<button
						*ngIf="!isShowSubscriptionMessage"
						class="s-mat-button save"
						mat-flat-button
						color="primary"
						(click)="isShowSubscriptionMessage = true"
					>
						{{ "buttons.request-change" | translate }}
					</button>
					<div *ngIf="isShowSubscriptionMessage">
						<div class="text-[16px] payment-message" [innerHTML]="'profile.terminate-subscription' | translate"></div>
						<div class="text-[16px] mt-2 payment-message" [innerHTML]="'profile.payout-change-request' | translate"></div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="tab === 'payout' && subrole === SubRole.STUDENT_MATCHED">
			<!-- <h1 class="text-lg mb-1">
				{{ "profile.your-membership" | translate }}
			</h1> -->
			<div class="form-row !mb-0">
				<div class="form-label">
					{{ "profile.billing-label" | translate }}
				</div>
				<div class="form-input">
					<div class="flex mb-6">
						<a class="billing-link flex align-middle text-black pt-[6px] font-bold" (click)="openPortal()">
							<span class="underline">
								{{ "profile.billing-link" | translate }}
							</span>
							<mat-icon class="portal-icon billing-link">open_in_new</mat-icon>
						</a>
					</div>
					<button
						*ngIf="!isShowPackageMessage"
						class="s-mat-button save"
						mat-flat-button
						color="primary"
						(click)="openTypeformPopUp()"
					>
						{{ "buttons.manage-membership" | translate }}
					</button>
					<div *ngIf="isShowPackageMessage">
						<div class="text-[16px] payment-message" [innerHTML]="'profile.package-change-request' | translate"></div>
						<div class="text-[16px] mt-2 payment-message" [innerHTML]="'profile.payout-change-request' | translate"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="flex md:justify-end w-full pt-6">
			<div *ngIf="tab !== 'payout'" class="form-input">
				<button class="s-mat-button save" mat-flat-button color="primary" (click)="saveTab(tab)" [disabled]="!isDirty(tab)">
					{{ "buttons.save-changes" | translate }}
				</button>
				<span *ngIf="isSavingError" class="error-message ml-2">
					{{ "forms.error" | translate }}
				</span>
				<span *ngIf="isSavedMessage" class="text-sirius-lilac ml-2">
					{{ "buttons.saved" | translate }}
				</span>
			</div>

			<div *ngIf="tab === 'payout' && subrole === SubRole.TEACHER_MATCHED" class="form-input">
				<button
					*ngIf="!isShowPayoutMessage"
					class="s-mat-button save"
					mat-flat-button
					color="primary"
					(click)="isShowPayoutMessage = true"
				>
					{{ "buttons.request-change" | translate }}
				</button>
				<div *ngIf="isShowPayoutMessage">
					<div class="payment-message" [innerHTML]="'profile.payout-change-request' | translate"></div>
				</div>
			</div>
		</div>
	</form>
	<app-notifications-settings *ngIf="tab === 'notifications'"> </app-notifications-settings>
</div>
