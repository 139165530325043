import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PublicCallRoomComponent } from "./components/call-room/component/public-call-room.component";
import { EndCallComponent } from "./end-call/end-call.component";
// import { LandingPageComponent } from './landing-page/landing-page.component';
import { RoomStateAuth } from "./components/call-room/guard/room-state.auth";
import { ImprintComponent } from "./imprint/imprint.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { LoginComponent } from "./login/login.component";
import { MailSentComponent } from "./mail-sent/mail-sent.component";
import { CreateProfileComponent } from "./create-profile/create-profile.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RestoreMailSentComponent } from "./restore-mail-sent/restore-mail-sent.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { StartComponent } from "./start/start.component";
import { LessonsPageComponent } from "./lessons-page/lessons-page.component";
import { MasterclassesComponent } from "./masterclasses/masterclasses.component";
import { RecordingsComponent } from "./recordings/recordings.component";
import { PlatformComponent } from "./platform/platform.component";
import { PricingComponent } from "./pricing/pricing.component";
import { AuthGuard } from "./components/call-room/guard/auth";
import { UnsupportedBrowserComponent } from "./unsupported-browser/unsupported-browser.component";
import { IsAuthorizedGuard } from "./components/admin/guard/is-authorized.guard";
import { OrganizationListComponent } from "./components/admin/util/organizations/organization-list/organization-list.component";
import { OrganizationCreateComponent } from "./components/admin/util/organizations/organization-create/organization-create.component";
import { IsNotAuthorizedGuard } from "./components/admin/guard/is-no-autorized.guard";
import { AdminComponent } from "./components/admin/admin.component";
import { AdminLoginComponent } from "./components/admin/login/admin-login.component";
import { UserCreateComponent } from "./components/admin/util/users/user-create/user-create.component";
import { UsersListComponent } from "./components/admin/util/users/users-list/users-list.component";
// import { IsNotSchoolGuard } from "./components/guard/is-not-school.guard";
import { CheckRegistrationResolver } from "./services/authentication/check-registration.resolver";
import { RoomAliasGuard } from "./lib-core/guards/room-alias.guard";
import { LoggedInGuard } from "./components/call-room/guard/loggedInGuard";
// import { RedirectGuard } from "./lib-core/guards/redirect.guard";
import { SetupComponent } from "./setup/setup.component";
import { SubRole } from "./constants/subroles-constants";
import { SubRoleGuard } from "./components/guard/subrole.guard";
import { StudentsComponent } from "./students/students.component";
import { PracticeRoomComponent } from "./praticeroom/practice-room/practice-room.component";
import { StudentNotesComponent } from "./student-notes/student-notes.component";
import { LessonsOverviewComponent } from "./lessons-overview/lessons-overview.component";
import { LessonsOverviewTableComponent } from "./lessons-overview/components/lessons-overview-table/lessons-overview-table.component";
// import { AvailabilityComponent } from "./availability/availability.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { ReferralComponent } from "./referral/referral.component";
import { ProfilePageComponent } from "./profile/profile-page/profile-page.component";
import { NotOnboardedGuard } from "./guards/not-onboarded.guard";
import { CheckoutAreaComponent } from "./checkout-area/checkout-area.component";
import { AvailabilitiesTabComponent } from "./availabilities-tab/availabilities-tab.component";
import { startPageGuard } from "./guards/start-page.guard";
import { OnboardingFormGuard } from "./guards/onboarding-form.guard";
import { TalkjsChatComponent } from "./talkjs-chat/talkjs-chat.component";
import { SubMenuComponent } from "./lessons-overview/mobile/sub-menu/sub-menu.component";

// ToDo: Rework 'signup-details' using route guard
const routes: Routes = [
	// be careful when you change it
	// { path: '', component: LoginComponent, data: {
	//   showHeader: false,
	//   isLoginPage: true,
	//   title: 'login-page.seo-title',
	//   description: 'login-page.seo-description'
	// }, canActivate: [LoggedInGuard]},

	// {
	// 	path: "",
	// 	canActivate: [RedirectGuard],
	// 	component: RedirectGuard,
	// 	data: {
	// 		externalUrl: "https://sirius.video",
	// 	},
	// },

	{
		path: "",
		component: LoginComponent,
		data: {
			showHeader: false,
			isLoginPage: true,
			title: "login-page.seo-title",
			description: "login-page.seo-description"
		},
		canActivate: [LoggedInGuard]
	},

	{ path: "end-call", component: EndCallComponent },
	{ path: "imprint", component: ImprintComponent },
	{
		path: "checkout",
		component: CheckoutAreaComponent,
		data: {
			title: "checkout-area.seo-title"
		}
	},
	{
		path: "practiceroom",
		component: PracticeRoomComponent,
		data: {
			showHeader: false,
			showFooter: false,
			title: "setup.seo-title"
		}
	},
	{
		path: "signup",
		component: SignUpComponent,
		canActivate: [LoggedInGuard],
		data: {
			showHeader: false,
			title: "signup-page.seo-title",
			description: "signup-page.seo-description"
		}
	},
	{
		path: "teach-signup",
		component: SignUpComponent,
		canActivate: [LoggedInGuard],
		data: {
			showHeader: false,
			title: "signup-page.seo-title",
			description: "signup-page.seo-description"
		}
	},
	{
		path: "signup-details",
		component: MailSentComponent,
		data: { showHeader: false }
	},
	{
		path: "forgot-password",
		component: ForgotPasswordComponent,
		data: {
			showHeader: false,
			title: "forgot-password.seo-title"
		}
	},
	{
		path: "recovery",
		component: RestoreMailSentComponent,
		data: { showHeader: false }
	},
	{
		path: "password/:key",
		component: ResetPasswordComponent,
		data: {
			title: "reset-password.seo-title"
		}
	},
	{
		path: "login",
		component: LoginComponent,
		data: {
			showHeader: false,
			isLoginPage: true,
			title: "login-page.seo-title",
			description: "login-page.seo-description"
		},
		canActivate: [LoggedInGuard]
	},
	{
		path: "unsupported-browser",
		component: UnsupportedBrowserComponent,
		data: {
			showHeader: false,
			showFooter: false,
			title: "unsupportedBrowser.seo-title"
		}
	},
	{
		path: "platform",
		component: PlatformComponent,
		canActivate: [NotOnboardedGuard],
		data: {
			footerStyle: "light",
			title: "platform-page.seo-title",
			showHeader: true
		},
		children: [
			{
				path: "start",
				component: StartComponent,
				canActivate: [SubRoleGuard, startPageGuard],
				data: {
					blockedSubroles: [SubRole.STUDENT_MATCHED]
				}
			},
			{
				path: "lessons",
				component: LessonsPageComponent,
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [SubRole.TEACHER_SELF]
				}
			},
			{
				path: "availability",
				component: AvailabilitiesTabComponent,
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [
						SubRole.STUDENT_MATCHED,
						SubRole.TEACHER_SELF
					]
				}
			},
			{
				path: "messages",
				component: TalkjsChatComponent,
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [SubRole.TEACHER_SELF]
				}
			},
			{ path: "masterclasses", component: MasterclassesComponent },
			// {
			// 	path: "lessons-overview",
			// 	component: LessonsOverviewComponent,
			// 	canActivate: [SubRoleGuard],
			// 	data: {
			// 		blockedSubroles: [SubRole.TEACHER_SELF]
			// 	},
			// 	children: [
			// 		{
			// 			path: "student-overview",
			// 			component: LessonsOverviewComponent
			// 		}
			// 	]
			// },
			{
				path: "recordings",
				component: RecordingsComponent
				//TO DO: Imrpove the Ausb Role Guard
			},

			{
				path: "profile",
				data: {
					title: "profile-page.seo-title",
					showHeader: true
				},
				children: [
					{
						path: "",
						component: ProfilePageComponent
					},
					{
						path: "personal",
						component: ProfilePageComponent
					},
					{
						path: "musical",
						component: ProfilePageComponent
					},
					{
						path: "payout",
						component: ProfilePageComponent
					},
					{
						path: "notifications",
						component: ProfilePageComponent
					}
				]
			},
			// {
			// 	path: "students",
			// 	component: StudentsComponent,
			// 	canActivate: [SubRoleGuard],
			// 	data: {
			// 		blockedSubroles: [
			// 			SubRole.STUDENT_MATCHED,
			// 			SubRole.TEACHER_SELF
			// 		]
			// 	}
			// },
			{
				path: "students",
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [
						SubRole.STUDENT_MATCHED,
						SubRole.TEACHER_SELF
					]
				},
				children: [
					{
						path: "",
						component: LessonsOverviewComponent
					},
					{
						path: "submenu",
						component: SubMenuComponent
					}
				]
			},
			{
				path: "notes",
				component: StudentNotesComponent,
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [
						SubRole.TEACHER_SELF,
						SubRole.TEACHER_MATCHED
					]
				}
			},
			{
				path: "referral",
				component: ReferralComponent,
				canActivate: [SubRoleGuard],
				data: {
					blockedSubroles: [SubRole.TEACHER_SELF]
				}
			}
		],
		canActivateChild: [AuthGuard]
	},
	{
		path: "platform/subscription",
		component: PricingComponent,
		canActivate: [SubRoleGuard],
		data: {
			showHeader: true,
			title: "pricing.seo-title",
			blockedSubroles: [SubRole.STUDENT_MATCHED]
		}
	},
	{
		path: "admin",
		component: AdminComponent,
		data: {
			showHeader: false,
			showFooter: false
		},
		children: [
			{
				path: "login",
				component: AdminLoginComponent,
				canActivate: [IsNotAuthorizedGuard]
			},
			{
				path: "create",
				component: OrganizationCreateComponent,
				canActivate: [IsAuthorizedGuard]
			},
			{
				path: "create-user",
				component: UserCreateComponent,
				canActivate: [IsAuthorizedGuard]
			},
			{
				path: "users-list",
				component: UsersListComponent,
				canActivate: [IsAuthorizedGuard]
			},
			{
				path: "",
				component: OrganizationListComponent,
				canActivate: [IsAuthorizedGuard]
			}
		]
	},
	{
		path: "feedback",
		loadChildren: () =>
			import("./lm-quality-rating/lm-quality-rating.module").then(
				(m) => m.LmQualityRatingModule
			),
		canLoad: [RoomAliasGuard],
		canActivate: [RoomAliasGuard],
		data: {
			showHeader: false,
			showFooter: false,
			title: "quality-rating.seo-title"
		}
	},
	{
		path: "setup",
		component: SetupComponent,
		data: {
			showHeader: false,
			showFooter: false,
			title: "setup.seo-title"
		}
	},
	{
		path: "teacher-onboarding",
		component: OnboardingComponent,
		canActivate: [AuthGuard, OnboardingFormGuard],
		data: {
			showHeader: false,
			showFooter: false,
			title: "teacher-onboarding.seo-title"
		}
	},
	{
		path: ":roomid",
		component: PublicCallRoomComponent,
		canActivate: [RoomStateAuth],
		data: { showHeader: false }
	},
	{
		path: "signup-details/:key",
		component: CreateProfileComponent,
		resolve: { checkRegistration: CheckRegistrationResolver },
		data: {
			showHeader: false,
			showFooter: false,
			title: "create-profile.seo-title"
		}
	},
	{ path: "**", redirectTo: "/" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled"
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
