import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { logger } from 'src/app/lib-core/logger';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Tariff, TariffUtil } from '../types/tariff-constants';
import { Profile } from '../types/profile.types';
import {DAILY_DEBUG, IS_SANDBOX, MONTHLY, VENDOR_ID, YEARLY} from '../constants/connection-constants';
import { GA4Service } from '../services/ga4.service';

export enum TariffPlan {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  DAILY_DEBUG = 'daily_debug'
}

declare global {
  interface Window { dataLayer: any[]; }
}
// declare function which is found in angular.json
declare function helpdeskBeaconGerman():void;
declare function helpdeskBeaconEnglish():void;
// declare Beacon to destroy later
declare let Beacon: any ;

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})

export class PricingComponent implements OnInit, OnDestroy {

  public profile: Profile;
  public tariff: Tariff;
  public tariffPlan: TariffPlan;
  public tariffPlanType = TariffPlan;

  isDebug: boolean = IS_SANDBOX;

  private destroy$: Subject<void> = new Subject();

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private ga4: GA4Service,
  ) {
    // load help beacon based on language
    const lang = this.translateService.currentLang
    if (lang == "deu"){helpdeskBeaconGerman()} else {
      helpdeskBeaconEnglish();
    }
    if (IS_SANDBOX) {
      // @ts-ignore
      Paddle.Environment.set('sandbox');
    }
    // @ts-ignore
    Paddle.Setup({
      vendor: VENDOR_ID,
      eventCallback: (data) => {
        console.log("success2")
        if (data.event === 'Checkout.Close' && data.eventData.checkout.completed) {
          this.authenticationService.resetProfile();
          this.router.navigate(['platform', 'start']);
        }
        if (data.event === "Checkout.Complete") {
          if (!!this.ga4.hasConsent) {
            this.ga4.yearlyAccount();}
            console.log(data.eventData);
          }
        } 
      });
    }

  ngOnInit() {
    this.authenticationService.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async(loggedIn: boolean) => {
        if (!!loggedIn) {
          this.profile = await this.authenticationService.profile;
        } else {
          this.profile = null;
        }
        this.definitionTariffProperties(this.profile);
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    Beacon('destroy');
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  payDaily(): void {
    this.setTariffPlan(TariffPlan.DAILY_DEBUG);
    this.setTariffPro();
  }

  public setTariffPlan(tariffPlan: TariffPlan): void {
    this.tariffPlan = tariffPlan;
  }
  
  public toggleTariffPlan(): void {
    this.tariffPlan = this.tariffPlan === this.tariffPlanType.MONTHLY 
    ? this.tariffPlanType.YEARLY 
    : this.tariffPlanType.MONTHLY
  }

  public setTariffPro(): void {
    logger.log('Debug profile: ', this.profile, 'tariff: ', this.tariff);
    if (this.tariff == null) {
      this.router.navigate(['/signup']);
    } else {
      let subscription = this.tariffPlan === TariffPlan.MONTHLY ? MONTHLY : YEARLY;
      if (this.tariffPlan === TariffPlan.DAILY_DEBUG && IS_SANDBOX) {
        subscription = DAILY_DEBUG;
      }
      logger.log('Sending to Paddle checkout: ', {
        product: subscription,
        email: this.profile.email,
        passthrough: {user_id: this.profile.userId}
      });
      // @ts-ignore
      Paddle.Checkout.open({
        product: subscription,
        email: this.profile.email,
        passthrough: {user_id: this.profile.userId},
      });
    }
  }

  public setTariffInstitutions(href: string): void {
    if (!!href) {
      const hrefData = href.toLowerCase();
      const hrefMailto = (!hrefData.includes('mailto:') ? 'mailto:' : '') + href;
      window.location.href = hrefMailto;
    }
  }

  public isTariffFree(tariff: Tariff): boolean {
    return (Tariff.FREE === tariff);
  }
  public isTariffPro(tariff: Tariff): boolean {
    return TariffUtil.isTariffPro(tariff);
  }
  public isTariffInstitutions(tariff: Tariff): boolean {
    return (Tariff.INSTITUTIONS === tariff);
  }
  public isTariffPlanYearly(tariffPlan: TariffPlan): boolean {
    return (TariffPlan.YEARLY === tariffPlan);
  }
  public isTariffPlanMonthly(tariffPlan: TariffPlan): boolean {
    return (TariffPlan.MONTHLY === tariffPlan);
  }
  public isTariffPlanDaily(tariffPlan: TariffPlan): boolean {
    return (TariffPlan.DAILY_DEBUG === tariffPlan);
  }

  // ** Private API **

  private definitionTariffProperties(profile: Profile): void {
    this.tariff = null;
    this.tariffPlan = TariffPlan.YEARLY;
    if (profile != null) {
      this.tariff = TariffUtil.create(profile.subscription.type);
      this.tariffPlan = (Tariff.PRO_MONTHLY === this.tariff ? TariffPlan.MONTHLY : TariffPlan.YEARLY);
    }
  }
}
