<ng-container *ngIf="isMediaError">
    <h2 class="audio-setup text-center">{{ 'setup.video-tab.camera-blocked' | translate }}</h2>
    <p class="text-center mb-2">{{ 'setup.grant-access'|translate }}</p>
    <img class="audio-input-mic mb-2" src="../../assets/images/setup/restricted.png">
    <!-- <p class="text-center mb-2"><a class="underline" href="">More about permissions</a>
    </p> -->
    <p></p>
</ng-container>
<form [formGroup]="preCallSettingsForm" [ngSwitch]="audioSettings">


    <ng-container *ngIf="!isMediaError">
        <h2 class="video-setup text-center">{{'setup.video-tab.title' | translate}}</h2>
        <div class="pre-setup-settings-container">
            <div class="video-container mb-2">
                <app-waiting-room-video [srcObject]="stream" _debugName_="'video-settings'"></app-waiting-room-video>
            </div>
            <p class="text-center text-sm mb-2">{{'setup.video-tab.question-1' | translate}}</p>
            <div [ngClass]="{'disabled-block': isMediaError && isFirefox}" class="row-media"
                *ngIf="videoDeviceList.length > 0">
                <div class="media-img icon camera"></div>
                <div class="media-prm">
                    <mat-select class="app-select video-settings-select" formControlName="videoInputDeviceId"
                        panelClass="app-select-panel" disableOptionCentering="true"
                        (selectionChange)="doChangeVideoInputDevice()">
                        <mat-option *ngFor="let device of videoDeviceList" [value]="device.deviceId">{{ device.label ||
                            device.groupId
                            }}</mat-option>
                    </mat-select>
                </div>
            </div>


            <div class="button-section justify-center">
                <a target="_blank" class="default-ui-button new-design-button-copy input"
                    href="https://support.sirius.video/article/136-meine-kamera-und-mein-mikrofon-funktionieren-nicht">
                    {{'setup.video-tab.answer-1-no' | translate}}
                </a>
                <button class="blue-ui-button" (click)="onCompleted()">
                    <!-- {{'setup.device-browser-tab.continue' | translate}} -->
                    {{'setup.video-tab.answer-1-yes' | translate}}
                    <span class=" ml-2 leading-6 material-symbols-outlined">arrow_forward</span>
                </button>
            </div>
        </div>
    </ng-container>
</form>