import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";
import { RoomStateStorage } from "../components/call-room/service/storage/RoomStateStorage";
import { PopupService } from "../services/popup/popup.service";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { PermissionsService } from "../permissions.service";
import { StorageUtil } from "../utils/storage.util";
import { ChatService } from "../components/chat/services/chat.service";
import { MenuSlide } from "./call-controls.animations";
import { SpeakerTimeDetectorService } from "../services/speaker-time-detector.service";
import { BotService } from "../services/bot/bot.service";
import { environment } from "@env/environment";
import { GA4Service } from "src/app/services/ga4.service";
import { PianoService } from "src/app/piano/service/piano.service";
import { WatchrtcService } from "../services/watchrtc.service";

type shareType = "YouTube" | "screenShare";
@Component({
	selector: "app-call-controls",
	templateUrl: "./call-controls.component.html",
	styleUrls: [
		"./call-controls.component.scss",
		"./call-controls.component.tablet.scss",
		"./call-controls.component.mobile.scss",
	],
	animations: [MenuSlide],
})
export class CallControlsComponent implements OnInit, OnDestroy {
	@Input() public isHiddenSetting = false;
	@Input() public isMicro: boolean;
	@Input() public isCamera: boolean;
	@Input() public isHiddenInterrupt = false;
	@Input() public isHiddenEmoji = false;
	@Input() public isEmoji = false;
	@Input() public isHandOn = false;
	@Input() public isHiddenMetronome = false;
	@Input() public isMetronome = false;
	@Input() public isHiddenTuner = false;
	@Input() public isTuner = false;
	@Input() public isHiddenScreenshare = false;
	@Input() public isScreenhare = false;
	@Input() public isYouTube = false;
	@Input() public isHiddenSplitView = false;
	@Input() public isSplitView = false;
	@Input() public isHiddenChat = false;
	@Input() public hasUnreadMessages = false;
	@Input() public isHiddenInvitation = false;
	@Input() public isHiddenLeaveButton = false;
	@Input() public isBotOrProTeacher = false;
	@Input() public isPiano = false;
	@Input() public switchCam = false;
	@Input() public isTeacher = false;
	@Input() public hasTestRoom = false;
	@Input() public isToggleShare = false;
	@Input() public displayNotes = false;
	@Input() public isHiddenNotes = false;
	@Input() public isStudentTrial = false;

	@Output() readonly toggleSettings: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleCamera: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleMicro: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleSwitchCam: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleEmoji: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleHandOn: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleMetronome: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleTuner: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleScreenhare: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleSplitView: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleChat: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleNotes: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleInvitation: EventEmitter<any> = new EventEmitter();
	@Output() readonly toggleRecord: EventEmitter<any> = new EventEmitter();
	@Output() readonly finishCall: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly emojiSelected: EventEmitter<string> = new EventEmitter();
	@Output() readonly togglePiano: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly toggleShare: EventEmitter<boolean> = new EventEmitter();
	@Output() readonly emitYoutubeVideoId: EventEmitter<string> = new EventEmitter();
	@Output() readonly toggleVideoSettings: EventEmitter<string> = new EventEmitter();
	@Output() readonly toggleAudioSettings: EventEmitter<string> = new EventEmitter();

	public isDesktop: boolean;
	public release: string;
	public isMore = false;
	public isMobile: boolean;
	public isTablet: boolean;
	public isPortrait: boolean;
	public isLandscape: boolean;
	public enableRecordings = environment.enableRecordings;
	public showAudioSubmenu = false;
	public showVideoSubmenu = false;
	@Input() public latencyTurnCamera: boolean;
	@Input() public displayChat = false;
	@Input() public startRecord = false;
	@Input() theme;
	@Input() public subrole: number;

	submenuHovered = false;
	videoSubmenuHovered = false;

	@HostListener("window:keydown", ["$event"])
	onKeyDown(event: KeyboardEvent): void {}

	//close 'more' tab on click off
	@HostListener("document:click", ["$event"])
	onClickOutside(event: Event) {
		if (!this._eref.nativeElement.contains(event.target)) {
			this.isMore = false;
		}
	}

	get hasCallInfoComponent(): boolean {
		return !!window.localStorage.getItem("callInfoComponent");
	}

	get isBot(): boolean {
		return BotService.isBot;
	}

	get hasCamera(): boolean {
		return !this.isBot;
	}

	constructor(
		private ga4: GA4Service,
		public roomState: RoomStateStorage,
		private platformDetectorService: PlatformDetectorService,
		private popupService: PopupService,
		public permissions: PermissionsService,
		public chatService: ChatService,
		private _eref: ElementRef,
		private speakerTimeDetectorService: SpeakerTimeDetectorService,
		private pianoService: PianoService,
        public watchrtcService: WatchrtcService
	) {}

	ngOnInit() {
		// TODO SIRIUS-1333 Disable shortcuts
		// this.onKeyDown = this.keyDownHandler;
		this.isScreenhare = false;
		this.isDesktop = this.platformDetectorService.isDesktop();
		this.release =
			this.platformDetectorService.getIOS_version().Full_Release;
		this.isMobile = this.platformDetectorService.isMobile();
		this.isTablet = this.platformDetectorService.isTabletDeviceDetectorOnly();
		this.isPortrait = this.platformDetectorService.isOrientationPortrait();
		this.isLandscape = !this.isPortrait;
	}

	canScreenshare(): boolean {
		return (
			!this.isTablet &&
			!this.isMobile &&
			!this.isHiddenScreenshare &&
			!this.platformDetectorService.isIPad()
		);
	}

	showSettingsForSafari(): boolean {
		return (
			!this.release ||
			(this.release &&
				!this.release.includes("15.1") &&
				!this.release.includes("15.2") &&
				!this.release.includes("15.3"))
		);
	}

	keyDownHandler(event: KeyboardEvent): void {
		if (!this.chatService.chatInputStatus && !this.hasCallInfoComponent) {
			const key = event.key && event.key.toLowerCase();
			switch (key) {
				case "v":
					this.doToggleCamera();
					break;
				case "m":
					this.doToggleMicro();
					break;
				case "c":
					this.doToggleChat();
					break;
				case "g":
					this.doToggleSplitView(!this.isSplitView);
					break;
				case "p":
					this.doToggleScreenShare(!this.isScreenhare);
					break;
				case "x":
					this.doToggleHandOn(!this.isHandOn);
					break;
				default:
				// handle other keys
			}
		}
	}

	doToggleSettings(event): void {
		this.toggleSettings.emit(!!event && event.altKey);
	}

	doToggleCamera(): void {

		// this.roomState.isCamOn = !this.roomState.isCamOn;
		// window.sessionStorage.setItem('isCamOn', JSON.stringify(this.roomState.isCamOn));
		StorageUtil.writeMediaPrmsToSessionStorage({ isCam: this.isCamera }); // Save to session storage.
		this.toggleCamera.emit(!this.isCamera);
        this.watchrtcService.trackEvent('toggleCamera', { isCamera: !this.isCamera });

	}

	doToggleMicro(): void {
		if (this.isMicro && this.roomState.isTeacher) {
			this.speakerTimeDetectorService.calculateLocalSpeakTime();
		}
		// this.roomState.isMicroOn = !this.roomState.isMicroOn;
		// window.sessionStorage.setItem('isMicroOn', JSON.stringify(this.roomState.isMicroOn));
		StorageUtil.writeMediaPrmsToSessionStorage({ isMicro: this.isMicro }); // Save to session storage.
		this.toggleMicro.emit(!this.isMicro);
        this.watchrtcService.trackEvent('toggleMicro', { isMicro: !this.isMicro });
	}

	doToggleSwitchCam(): void {
		if (!this.latencyTurnCamera) {
			this.switchCam = !this.switchCam;
			this.toggleSwitchCam.emit(this.switchCam);
		}
	}

	doToggleEmoji(isEmoji: boolean): void {
		if (this.isEmoji !== isEmoji) {
			this.toggleEmoji.emit(isEmoji);
			this.ga4.toogle_emoji();
		}
	}

	doToggleHandOn(isHandOn: boolean): void {
		// this.roomState.isHandOn = !this.roomState.isHandOn;
		this.toggleHandOn.emit(isHandOn);
		this.ga4.play_sound();
	}

	doToggleMetronome(isMetronome: boolean): void {
		if (this.isMetronome !== isMetronome) {
			this.toggleMetronome.emit(isMetronome);
			this.ga4.open_metronome();
		}
	}

	doToggleTuner(isTuner: boolean): void {
		if (this.isTuner !== isTuner) {
			this.toggleTuner.emit(isTuner);
		}
	}

	doToggleScreenShare(isScreenhare: boolean): void {
		if (this.isScreenhare !== isScreenhare) {
			this.toggleScreenhare.emit(isScreenhare);
            this.watchrtcService.trackEvent('toggleScreenShare', { isScreenhare: isScreenhare });
		}
	}

	doToggleShare() {
		this.isToggleShare = !this.isToggleShare;
		this.toggleShare.emit(this.isToggleShare);
	}

	onSelectShareType(type: shareType) {
		if (type === "screenShare") {
			this.doToggleScreenShare(!this.isScreenhare);
		} else {
			this.doToggleYoutube(!this.isYouTube);
		}
		this.doToggleShare();
	}

	doToggleYoutube(isYouTube: boolean) {
		if (isYouTube) {
			this.popupService.openYoutubeDialog("", "", [
				{
					type: ButtonType.CANCEL,
					text: "",
				},
				{
					type: ButtonType.OK,
					text: "",
					callBack: (url) => {
						const params = new URL(url).searchParams;
						localStorage.setItem("hostPlayer", "true");
						this.emitYoutubeVideoId.emit(params.get("v"));
					},
				},
			]);
			this.ga4.youtube();
		} else {
			localStorage.removeItem("hostPlayer");
			this.emitYoutubeVideoId.emit(null);
		}
	}

	doToggleSplitView(isSplitView: boolean): void {
		this.toggleSplitView.emit(isSplitView);
		this.ga4.changeView();
	}

	doToggleChat(): void {
		this.displayChat = !this.displayChat;
		this.toggleChat.emit(this.displayChat);
		this.ga4.chat();
	}

	doToggleNotes(): void {
		this.displayNotes = !this.displayNotes;
		this.toggleNotes.emit(this.displayNotes);
	}

	doToggleInvitation(): void {
		this.toggleInvitation.emit();
	}

	doToggleRecord(): void {
		this.startRecord = !this.startRecord;
		this.toggleRecord.emit(this.startRecord);
		this.ga4.record();
	}

	doFinishCall() {
		this.popupService.closeAll();

		window.sessionStorage.removeItem("skipDemand");
		window.sessionStorage.removeItem("hidePassword");
		localStorage.removeItem("videoId");
		localStorage.removeItem("hostPlayer");
		localStorage.removeItem("subrole");
		this.finishCall.emit(false);
		this.ga4.endSession();
	}

	onEmojiSelected(emoji: string) {
		this.emojiSelected.emit(emoji);
	}

	setIsMore(value: boolean) {
		this.isMore = value;
	}

	get isMobLandscape() {
		return (
			this.platformDetectorService.isMobile() &&
			this.platformDetectorService.isOrientationLandscape()
		);
	}

	ngOnDestroy() {
		this.onKeyDown = (): void => {};
	}

	// this one gets an event out of call-controls, the sets isPiano

	doTogglePiano(isPiano: boolean): void {
		if (this.isPiano !== isPiano) {
			this.togglePiano.emit(isPiano);
			this.pianoService.isPiano.next(isPiano);
		}
	}

	doToggleVideoSettings() {
		this.toggleVideoSettings.emit();
	}

	doToggleAudioSettings() {
		this.toggleAudioSettings.emit();
	}

	showAudioSettings(): void {
		this.showAudioSubmenu = true;
		this.submenuHovered = true;
	}

	hideAudioSettings(): void {
		setTimeout(() => {
			this.submenuHovered = false;
			this.showAudioSubmenu = false;
		}, 200);
	}

	showVideoSettings(): void {
		this.showVideoSubmenu = true;
		this.videoSubmenuHovered = true;
	}

	hideVideoSettings(): void {
		setTimeout(() => {
			this.videoSubmenuHovered = false;
			this.showVideoSubmenu = false;
		}, 200);
	}
}
