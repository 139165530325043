import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProfileService } from "../profile/profile.service";
import { TeacherStatus } from "../types/profile.types";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { CountriesService } from "../services/countries/countries.service";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { UtcDateAdapter } from "../utils/convert-time.util";
import { forkJoin } from "rxjs";
import { ProfileFormComponent } from "../profile/profile-form/profile-form.component";
import { LanguageService } from "../services/languages/language.service";

@Component({
	selector: "app-onboarding",
	templateUrl: "./onboarding.component.html",
	styleUrls: ["./onboarding.component.scss"],
	providers: [
		{ provide: DateAdapter, useClass: UtcDateAdapter },
		{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }
	]
})
export class OnboardingComponent extends ProfileFormComponent {
	formSection = 0;
	onboardingSteps = [
		"about",
		"photo",
		"musicalDetails",
		"profileDescription",
		"setup",
		"students",
		"payoutDetails",
		"terms"
	];
	constructor(
		protected fb: FormBuilder,
		protected profileService: ProfileService,
		protected router: Router,
		protected authenticationService: AuthenticationService,
		protected countriesService: CountriesService,
		protected translate: TranslateService,
		protected languageService: LanguageService
	) {
		super(
			fb,
			profileService,
			router,
			authenticationService,
			countriesService,
			translate,
			languageService
		);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	logOut(): void {
		this.authenticationService.logOut$().subscribe(() => {
			this.router.navigate(["/"]);
		});
	}

	nextSection() {
		this.isShowErrors = false;
		this.formSection++;
	}

	previousSection() {
		this.formSection--;
	}

	moveToStep(i) {
		if (this.isClickable(i)) {
			this.formSection = i;
		}
	}

	isClickable(i) {
		if (i === 0) {
			return true;
		}
		return this.form.get(this.onboardingSteps[i - 1]).valid;
	}

	override savePhoto() {
		if (this.photo.dirty) {
			this.isSavingError = false;
			const file = this.photo.get("photo").value;
			this.profileService.savePhoto(file).subscribe(
				(res) => {
					this.photo.patchValue({
						photo: res["photo"]
					});

					this.photo.markAsPristine();
					this.nextSection();
				},
				() => {
					this.isSavingError = true;
				}
			);
		} else if (!this.photo.valid) {
			this.isShowErrors = true;
			this.photo.updateValueAndValidity();
		} else {
			this.nextSection();
		}
	}

	saveCertificate(instrumentIndex) {}

	onFileSelected(event, instrumentIndex) {
		const file: File = event.target.files[0];
		// this.profileService.saveInstrumentCert(file).subscribe(console.log);
	}

	finish() {
		this.isSavingError = false;

		const data = {
			...this.terms.value,
			payoutDetailsComplete: true
		};

		if (this.terms.valid) {
			if (this.teacherStatus !== TeacherStatus.REVIEW) {
				forkJoin([
					this.profileService.save(data),
					this.profileService.reviewTeacher(this.userId)
				]).subscribe(
					() => {
						// this.router.navigate(["/platform/lessons"]);
						this.teacherStatus = TeacherStatus.REVIEW;
					},
					(error) => {
						console.error("An error occurred:", error);
						this.isSavingError = true;
					}
				);
			}
		} else {
			this.isShowErrors = true;
		}
	}

	override saveGroup(group: FormGroup) {
		if (group.valid && !group.dirty) {
			this.form.markAsPristine();
			this.nextSection();
		} else if (group.dirty && group.valid) {
			this.isSavingError = false;
			const data = group.value;

			// if (group === this.about) {
			// 	data.gender = this.genderValue;
			// }
			if (group === this.students) {
				data.gendersPreference = this.gendersPreferenceValue;
				data.agesPreference = this.agesPreferenceValue;
				data.skillLevelPreference = this.skillLevelPreferenceValue;
			}

			this.profileService.save(group.value).subscribe(() => {
				this.form.markAsPristine();
				this.nextSection();
			});
		} else {
			group.markAllAsTouched();
			this.isShowErrors = true;
		}
	}

	showReviewPage() {
		return this.teacherStatus === TeacherStatus.REVIEW;
	}
}
