import { Component, ViewEncapsulation, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { getSubDomain } from "../helpers";
import { Tariff } from "../types/tariff-constants";
import { Resources } from "../services/access-control/resources.constants";
import { SubRole } from "../constants/subroles-constants";
import { TalkServiceService } from "../talkjs-chat/services/talk-service.service";
import { ENCODED_LANGUAGES } from "../constants/encodedLanguages-contants";

@Component({
	selector: "app-platform-menu",
	templateUrl: "./platform-menu.component.html",
	styleUrls: ["./platform-menu.component.scss"],
	encapsulation: ViewEncapsulation.Emulated
})
export class PlatformMenuComponent implements OnInit {
	@Input() isAdmin: boolean = false;
	public isSchool: boolean;
	public enableRecordings = environment.enableRecordings;
	public lessonOverviewUrl = "";
	public tariff: Tariff;
	public subrole: SubRole;
	public Resources = Resources;
	public isPersonalRoom = true;
	public unreadMessages: number;
	private isBetaTester: boolean = false;

	get isMatchedTeacher() {
		return this.subrole === SubRole.TEACHER_MATCHED;
	}

	constructor(
		public authenticationService: AuthenticationService,
		private talkService: TalkServiceService,
		private router: Router
	) {}

	async ngOnInit(): Promise<void> {
		this.isSchool = !!getSubDomain();
		const profile = await this.authenticationService.profile;
		this.subrole = profile?.subrole;
		this.lessonOverviewUrl = profile?.lessonOverview;
		this.isBetaTester = profile?.betaTesting;
		this.isPersonalRoom = profile?.enablePersonalRoom;
		this.countUnreadTalkjsMessages(profile);
	}

	async countUnreadTalkjsMessages(profile) {
		const userData = {
			id: profile.userId,
			username: `${profile.firstName} ${profile.lastName}`,
			email: profile.email,
			photoUrl: profile.photo,
			locale: this.validateLnaguage(profile.languageId)
		};
		await this.talkService.intializeUser(userData);
		(await this.talkService.unreadMessages()).onChange((unreads) => {
			this.unreadMessages = unreads.reduce((total, conversation) => {
				return total + conversation.unreadMessageCount;
			}, 0);
		});
	}

	showLessonManagement(): boolean {
		return this.isBetaTester;
	}

	// getCalComLink(): string {
	// 	const access = localStorage.getItem("accessToken");
	// 	const refresh = localStorage.getItem("refreshToken");
	// 	return `${environment.calcomUrl}/slogin?access=${access}&refresh=${refresh}`;
	// }

	logOut(): void {
		this.authenticationService.logOut$().subscribe((res) => {
			if (this.isAdmin) {
				this.router.navigate(["admin/login"]);
			} else {
				if ("/" === environment.logoutUrl) {
					this.router.navigate([environment.logoutUrl]);
				} else {
					window.location.href = environment.logoutUrl;
				}
			}
		});
	}

	validateLnaguage(encodedLanguageId: string): string {
		return ENCODED_LANGUAGES[encodedLanguageId];
	}
}
