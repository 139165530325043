import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PlatformService } from "./platform.service";
// import { AuthenticationService } from "../services/authentication/authentication.service";
// import { Profile } from "../types/profile.types";
// import { Tariff } from "../types/tariff-constants";
// import { getSubDomain } from "../helpers";
// import { PopupService } from "../services/popup/popup.service";

@Component({
	selector: "app-platform",
	templateUrl: "./platform.component.html",
	styleUrls: ["./platform.component.scss"],
})
export class PlatformComponent implements OnInit {
	// public profile: Profile;
	// public readonly Tariff = Tariff;
	// public isSchool: boolean;

	constructor(
		public platformService: PlatformService,
		private router: Router,
		// private authenticationService: AuthenticationService,
		// private popupService: PopupService
	) {}

	async ngOnInit(): Promise<void> {
		// this.profile = await this.authenticationService.profile;

		// const url = new URL(window.location.href);
		// const isRecordingsPage = url.pathname.includes("recordings");
		// this.isSchool = !!getSubDomain();
		
		// if (
		// 	!isRecordingsPage &&
		// 	(this.profile.subrole === 3 || this.isSchool)
		// ) {
		// 	this.popupService.openMusicDialog(this.profile);
		// }
	}

	// To do: delete matomo, bring ga4 in.

	siriusNow() {
		this.platformService.siriusNow().then(async (roomUrl) => {
			await this.router.navigate([roomUrl]);
		});
	}
}
